import React from "react";
import { LuHeart, LuRefreshCcw } from "react-icons/lu";

const StoryCard = ({ story }) => {
  return (
    <div className="md:bg-secondary/50 bg-secondary/70 rounded-2xl p-2 min-h-60 relative">
      <div className="flex h-full gap-4">
        <img
          src={story._source.selectedcoverart_text}
          alt={story._source.title_text}
          className="w-40 h-full rounded-xl object-cover"
        />
        <div className="flex flex-col gap-2 py-6">
          <h3 className="text-base font-extrabold ">
            {story._source.title_text}
          </h3>
          <div className="flex gap-2 justify-between py-2">
            <p className="text-xs font-bold text-foreground/60">
              {story._source.username_text}
            </p>
            <div className="flex gap-1 text-xs pe-4">
              <LuHeart size={16} className="" />
              {story._source.score_number}
            </div>
          </div>
          <p className="text-xs leading-5 pe-2 line-clamp-4 text-foreground/80">
            {story._source.description_text}
          </p>
        </div>
      </div>
      <div className="flex gap-1 text-xs pe-4 absolute bottom-4 right-2">
        <LuRefreshCcw size={14} className="" />
        <span className="">Remix this story</span>
      </div>
    </div>
  );
};

export default StoryCard;
