import {
  assetUrl,
  getCurrentStyleKey,
  getHairOptions,
  getHairTypes,
} from "../Helpers";
import { getImageUrl } from "./ChooseEthnicity";

const ChooseHairStyle = ({
  style,
  changeHair,
  hairStyle,
  hairColor,
  eyeColor,
  data,
}) => {
  const { baseUrl, types } = getHairTypes(style);
  const key = getCurrentStyleKey(style);

  const baseClass =
    "rounded-[26px] lg:rounded-[30px] transform transition-transform hover:scale-105 duration-300 overflow-hidden border-[4.5px] border-transparent";

  const selectedClass = `${baseClass} selected`;

  const getClass = (item, subItem) => {
    const baseClass =
      "rounded-[26px] lg:rounded-[30px] transform transition-transform hover:scale-105 duration-300 overflow-hidden border-[4.5px] border-transparent";
    const selectedClass = `${baseClass} selected`;
    const nonSelectedClass = `${baseClass} opacity-20`;

    const typeMapping = {
      style: hairStyle,
      color: hairColor,
      "eye-color": eyeColor,
    };

    const selectedValue = typeMapping[item.type];
    return subItem.name === selectedValue ? selectedClass : nonSelectedClass;
  };

  const renderOption = (item, subItem) => {
    if (!subItem.allow.includes(key)) {
      return null;
    }
    return (
      <div
        className="relative rounded-3xl overflow-hidden"
        key={subItem.name}
        onClick={() => changeHair(item.type, subItem.name)}
      >
        <div className={getClass(item, subItem)}>
          <img
            alt={subItem.name}
            className="object-contain cursor-pointer rounded-3xl"
            src={assetUrl(`${baseUrl}${item.type}/${subItem.image}`)}
          />
          <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2 cursor-pointer">
            <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
              {subItem.name}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOptions = (item) => {
    let options = getHairOptions()[item.type] || [];
    let topOptions = [],
      bottomOptions = [];

    if (item.type === "style") {
      topOptions = options.slice(0, 3);
      bottomOptions = options.slice(3);
    } else {
      topOptions = options;
    }

    return (
      <div className="flex gap-2 lg:gap-4 justify-center flex-col lg:flex-row">
        <div className="flex flex-wrap gap-4 justify-center">
          {topOptions.map((subItem) => renderOption(item, subItem))}
        </div>
        {bottomOptions.length > 0 && (
          <div className="flex flex-wrap gap-4 justify-center">
            {bottomOptions.map((subItem) => renderOption(item, subItem))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mt-10">
      <div className="mb-5 mt-5" key={"hair styles"}>
        <div className="text-white w-full font-bold text-center text-md mb-6 lg:mb-4">
          Choose Hair styles
        </div>
        <div
          className="flex flex-wrap gap-4 justify-center"
          style={{
            maxWidth: "56rem",
            margin: "0 auto",
          }}
        >
          {data.attributes.map((item, index) => {
            const attribute = style === "Anime" ? item.anime : item.realistic;

            return (
              <div
                className="relative rounded-3xl overflow-hidden"
                key={attribute.name}
                onClick={() => changeHair("style", item.name)}
              >
                <div
                  className={
                    hairStyle === item.name ? selectedClass : baseClass
                  }
                >
                  <img
                    alt={attribute.name}
                    className="object-contain cursor-pointer rounded-3xl"
                    src={getImageUrl(attribute.image.url)}
                  />
                  <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2 cursor-pointer">
                    <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {types.map((item, index) => (
        <div className="mb-5 mt-5" key={index}>
          <div className="text-white w-full font-bold text-center text-md mb-6 lg:mb-4">
            Choose {item.name}
          </div>
          {renderOptions(item)}
        </div>
      ))}
    </div>
  );
};

export default ChooseHairStyle;
