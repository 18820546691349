import React from "react";
import { Input } from "../../../Components/input";
import {
  LuFlame,
  LuHeart,
  LuSearch,
  LuSparkle,
  LuSparkles,
} from "react-icons/lu";
import { Button } from "../../../Components/button";

const searchButtons = [
  { name: "All", href: "#", icon: LuSparkle },
  { name: "Fantasy", href: "#", icon: LuSparkles },
  { name: "Romance", href: "#", icon: LuHeart },
  { name: "Erotic", href: "#", icon: LuFlame },
];

const SearchFilter = () => {
  const [activeTab, setActiveTab] = React.useState(1);
  return (
    <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between max-w-7xl mx-auto px-4 xl:pt-20">
      <div className="relative">
        <div className="flex h-full absolute top-0 left-0 justify-center items-center ps-4">
          <LuSearch size={20} className="" />
        </div>

        <Input
          className="rounded-full bg-secondary h-14 w-full md:w-[500px]  ps-12"
          placeholder="Search stories by tags, titles or description"
        />
      </div>
      <div className=" flex flex-wrap gap-2 lg:border-b my-auto">
        {searchButtons.map((tab, index) => (
          <Button
            key={tab.id}
            type="button"
            variant={activeTab === index ? "default" : "secondary_light"}
            className="w-fit"
            onClick={() => setActiveTab(index)}
            //   className="flex items-center justify-center gap-2 w-full h-12 text-foreground/80 bg-transparent rounded-lg hover:bg-foreground/10"
          >
            <tab.icon size={20} />
            <span>{tab.name}</span>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SearchFilter;
