import React from "react";
import { LuHome, LuImage, LuMap, LuPen } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../../../lib/utils";

const navigationLinks = [
  {
    link: "/tpl02",
    label: "Home",
    icon: <LuHome />,
  },
  {
    link: "#",
    label: "Write",
    icon: <LuPen />,
  },
  {
    link: "#",
    label: "Explore",
    icon: <LuMap />,
  },
  {
    link: "#",
    label: "Images",
    icon: <LuImage />,
  },
];

const BottomNavbar = () => {
  const location = useLocation();
  return (
    <div className="fixed bottom-0 z-[1000] w-full border-t bg-background p-1.5 sm:hidden">
      <div className="grid grid-cols-4">
        {navigationLinks.map((link, i) => (
          <Link
            to={link.link}
            key={i}
            className={cn(
              `flex flex-col items-center gap-1 rounded-lg py-2 text-zinc-400`,
              location.pathname == link.link ? " text-primary" : "text-zinc-400"
              // user?.role[0] === "Pro" && link.link === "/premium" && "hidden",
              // user?.role[0] !== "Pro" && link.link === "/tokens" && "hidden",
              // link.link === "/tokens" && ""
            )}
          >
            <div className="!text-[26px]">{link.icon}</div>
            <span className="text-[14px]">{link.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BottomNavbar;
