import React from "react";
import Hero from "./components/hero";
import ExploreAiGIrlfriend from "./components/old_ui/explore-ai-girlfriend";
import Features from "./components/features";
import Faq from "./components/old_ui/faq";
import CTA from "./components/old_ui/cta";
import ExperienceOurAI from "./components/experience-our-ai";
import { FaCircle } from "react-icons/fa";

export default function HentaiGirlfriend({ allRedirect }) {
  console.log("🚀 ~ HentaiGirlfriend ~ allRedirect:", allRedirect);
  return (
    <main className="mx-auto max-w-screen-2xl p-4">
      <div className="flex justify-center items-center text-2xl font-black tracking-widest text-primary pb-20">
        <FaCircle
          className="absolute -top-20 blur-3xl text-primary"
          size={100}
        />
        Havengen.ai
      </div>
      <div className="py-4"></div>
      <Hero allRedirect={allRedirect} />
      <div className="py-10"></div>

      <ExperienceOurAI allRedirect={allRedirect} />
      <div className="mt-32"></div>

      <ExploreAiGIrlfriend allRedirect={allRedirect} />
      {/* <ImageGenerator /> */}
      <Features allRedirect={allRedirect} />
      {/* <ExperienceOurAI /> */}
      <Faq allRedirect={allRedirect} />
      <CTA allRedirect={allRedirect} />
    </main>
  );
}
