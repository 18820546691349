export const landerSettings = {
  styles: [
    {
      name: "Realistic",
      key: "realistic",
      base: "assets/realistic/",
      image: "realistic.jpg",
    },
    {
      name: "Anime",
      key: "anime",
      image: "anime.jpg",
      base: "assets/anime/",
    },
  ],
  voices: ["Innocent", "Standard", "Seductress", "Dominant"],
  relationships: [
    [
      {
        name: "Step sister",
        icon: "assets/icons/step_sister.svg",
      },
      {
        name: "Step Mom",
        icon: "assets/icons/step_mom.svg",
      },
    ],
    [
      {
        name: "Girlfriend",
        icon: "assets/icons/girlfriend.svg",
      },
      {
        name: "Sex friend",
        icon: "assets/icons/sex_friend.svg",
      },
    ],
    [
      {
        name: "Wife",
        icon: "assets/icons/wife.svg",
      },
      {
        name: "Lover",
        icon: "assets/icons/lover.svg",
      },
    ],
  ],
  hair: {
    types: [
      // {
      //   name: "Hair Style",
      //   type: "style",
      // },
      {
        name: "Hair Color",
        type: "color",
      },
      {
        name: "Eye Color",
        type: "eye-color",
      },
    ],
    options: {
      style: [
        {
          name: "Straight",
          image: "straight.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Short",
          image: "short.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Braids",
          image: "braids.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Bun",
          image: "bun.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Curly",
          image: "curly.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Ponytail",
          image: "ponytail.webp",
          allow: ["realistic", "anime"],
        },
      ],
      color: [
        {
          name: "Blonde",
          image: "blonde.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Brunette",
          image: "brunette.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Black",
          image: "black.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Pink",
          image: "pink.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Redhead",
          image: "redhead.webp",
          allow: ["realistic", "anime"],
        },
      ],
      "eye-color": [
        {
          name: "Brown",
          image: "brown.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Yellow",
          image: "yellow.webp",
          allow: ["anime"],
        },
        {
          name: "Blue",
          image: "blue.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Green",
          image: "green.webp",
          allow: ["realistic", "anime"],
        },
        {
          name: "Red",
          image: "red.webp",
          allow: ["anime"],
        },
      ],
    },
  },
  body: {
    types: [
      {
        name: "Body Type",
        type: "type",
      },
      {
        name: "Breast Size",
        type: "breast",
      },
      {
        name: "Butt Size",
        type: "butt",
      },
    ],
    options: {
      type: [
        {
          name: "Slim",
          image: "slim.webp",
        },
        {
          name: "Athletic",
          image: "athletic.webp",
        },
        {
          name: "Voluptuous",
          image: "voluptuous.webp",
        },
        {
          name: "Curvy",
          image: "curvy.webp",
        },
      ],
      breast: [
        {
          name: "Small",
          image: "small.webp",
        },
        {
          name: "Medium",
          image: "medium.webp",
        },
        {
          name: "Large",
          image: "large.webp",
        },
        {
          name: "Huge",
          image: "huge.webp",
        },
      ],
      butt: [
        {
          name: "Skinny",
          image: "skinny.webp",
        },
        {
          name: "Medium",
          image: "medium.webp",
        },
        {
          name: "Athletic",
          image: "athletic.webp",
        },
        {
          name: "Large",
          image: "large.webp",
        },
      ],
    },
  },
  ethnicities: [
    {
      name: "Caucasian",
      bg: "caucasian-bg.webp",
      "image-left": "caucasian-left.webp",
      "image-center": "caucasian.webp",
      "image-right": "caucasian-right.webp",
    },
    {
      name: "Asian",
      bg: "asian-bg.webp",
      "image-left": "asian-left.webp",
      "image-center": "asian.webp",
      "image-right": "asian-right.webp",
    },
    {
      name: "Arab",
      bg: "arab-bg.webp",
      "image-left": "arab-left.webp",
      "image-center": "arab.webp",
      "image-right": "arab-right.webp",
    },
    {
      name: "Black",
      bg: "black-bg.webp",
      "image-left": "black-left.webp",
      "image-center": "black.webp",
      "image-right": "black-right.webp",
    },
    {
      name: "Latina",
      bg: "latina-bg.webp",
      "image-left": "latina-left.webp",
      "image-center": "latina.webp",
      "image-right": "latina-right.webp",
    },
  ],
};
