import { LuRocket } from "react-icons/lu";
import { Button } from "../../../Components/button";

export default function CTA() {
  return (
    <div className="">
      <div className="px-6 py-24 sm:px-6 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <p className="mx-auto  max-w-xl text-lg leading-8 text-foreground/50">
            Try our magical story generator
          </p>

          <h2 className="text-3xl mt-6 font-bold tracking-tight text-foreground md:text-6xl">
            FROM IDEA TO STORY IN JUST 2 MINUTES
          </h2>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              variant="default"
              size="xxl"
              className="mt-4 rounded-xl mx-auto px-12 text-base btn"
            >
              <LuRocket /> Click here to create your own
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
