import { GoDotFill } from "react-icons/go";
import { MdChatBubble } from "react-icons/md";
import { FaHeart } from "react-icons/fa";

export default function GirlfirendCard({ girlfriend }) {
  return (
    <div className="group relative w-full overflow-hidden rounded-lg">
      <img
        className="duration-30 aspect-[3/5] w-full overflow-clip object-cover object-center object-top transition-transform group-active:scale-105 duration-500"
        src={girlfriend.image}
      ></img>
      <div className="absolute top-0 flex h-full w-full flex-col justify-end bg-gradient-to-t from-black/80 via-black/20 to-transparent p-4 pb-2">
        <p className="text-base font-bold">{girlfriend.name}</p>
        <p className="mb-2 text-xs text-foreground/60">
          {girlfriend.description}
        </p>
      </div>

      <div className="absolute right-2 top-2 my-auto flex gap-1 rounded-full bg-white/60 p-2  font-semibold">
        <MdChatBubble className="my-auto text-primary" />
      </div>
      <div className="absolute right-2 top-12 my-auto flex gap-1 rounded-full bg-white/60 p-2  font-semibold">
        <FaHeart className="my-auto text-primary" />
      </div>
    </div>
  );
}
