import React from "react";
import qs from "qs";

const StrapiUpdatePage = () => {
  const pageToUpdate = "lander-3";
  const writeToken = process.env.REACT_APP_STRAPI_ACCESS_WRITE_TOKEN_SECRET;

  const handleUpdateData = async () => {
    try {
      const payload = mainPayload;

      // Log the payload
      console.log("Payload to send:", payload);

      // Update lander-3 with the data from lander-1
      const updateResponse = await fetch(
        `http://195.16.73.90:1337/api/${pageToUpdate}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${writeToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!updateResponse.ok) {
        const errorData = await updateResponse.json();
        console.error("Update Error Details:", errorData);
        throw new Error("Failed to update lander-3");
      }

      const updateData = await updateResponse.json();
      console.log("Update successful:", updateData);
      alert("Data has been successfully copied to lander-3");
    } catch (error) {
      console.error("Error updating data:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <button onClick={handleUpdateData}>Update Data</button>
    </div>
  );
};

export default StrapiUpdatePage;

export const mainPayload = {
  data: {
    title: "Choose style",
    all_destination_redirect: "https://pornhaven.ai/girlfriends",
    style: [
      {
        text: "Realistic",
        image: 61,
      },
      {
        text: "Anime",
        image: 62,
      },
    ],
    final_button: {
      button_text: "Create Girlfriend Now",
      href: "https://pornhaven.ai/girlfriend/",
    },
  },
  meta: {},
};
