/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../Components/button";
import { Link } from "react-router-dom";

export default function Hero({ allRedirect }) {
  return (
    <div className="relative isolate overflow-hidden rounded-xl md:py-20 pb-0 md:pb-20  md:ring ring-white/10 ">
      <div className="mx-auto max-w-7xl pb-0 md:pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-10 flex flex-col md:flex-row gap-10">
        <div className="relative z-10 md:px-6">
          <div className="mx-auto max-w-2xl">
            <div className="max-w-lg">
              <div className="">
                <a href="#" className="inline-flex space-x-6">
                  <span className="inline-flex items-center space-x-2 rounded-full bg-rose-500/10 px-3 py-1 text-sm font-medium leading-6  text-primary ring-1 ring-inset ring-white/10">
                    <span>Get started with your AI Hentai</span>
                    <ChevronRightIcon
                      className="h-5 w-5 text-primary"
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </div>
              <h1 className="mt-10 text-4xl font-bold tracking-tight text-primary sm:text-4xl">
                The Best AI Hentai Chat
              </h1>
              <p className="mt-2 text-lg leading-6 text-white/50">
                Our smartest AI hentai lets you chat with your favourite anime
                character and also lets you generate images .
              </p>
              <div className="mt-6 flex items-center gap-x-6">
                <Link to={"/tpl01"}>
                  <Button className="md:py-6 md:text-lg !font-bold">
                    Create AI Girlfriend
                  </Button>
                </Link>
                <Link to={allRedirect}>
                  <Button className="md:py-6 md:text-lg !font-bold">
                    Generate Image
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="md:absolute right-0 top-0">
          <div className="relative w-full">
            <img
              src="https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/68c20a50-fbca-4756-83a0-24ea82ee7a27/original=true/5D8F5C2A82B0244F53CDEFD4494E518264009E5FF3906F34C8B514BB3D6CE76C.jpeg"
              className="md:h-[550px] w-full object-cover object-top rounded-lg md:rounded-none"
            ></img>
            <div className="absolute hidden md:flex left-0 top-0 h-full w-96 bg-gradient-to-r from-[#0c0c0c] via-[#0c0c0c]/70 to-transparent"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
