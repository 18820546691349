import { assetUrl, getBodyOptions, getBodyTypes } from "../../../Helpers";

const ChooseBodyType = ({
  style,
  changeBody,
  bodyType,
  bodyBreast,
  bodyButt,
}) => {
  const { baseUrl, types } = getBodyTypes(style);
  const getClass = (item, subItem) => {
    const baseClass =
      "rounded-[26px] lg:rounded-[30px] transform transition-transform hover:scale-105 duration-300 overflow-hidden border-[4.5px] border-transparent";
    const selectedClass = `${baseClass} selected`;
    const nonSelectedClass = `${baseClass} opacity-20`;
    const typeMapping = {
      type: bodyType,
      breast: bodyBreast,
      butt: bodyButt,
    };
    const selectedValue = typeMapping[item.type];
    if (!selectedValue) {
      return baseClass;
    }
    return subItem.name === selectedValue ? selectedClass : nonSelectedClass;
  };
  return (
    <div className="mt-10">
      {types.map((item, index) => {
        const options = getBodyOptions()[item.type] || [];
        return (
          <div className="mb-5 mt-5" key={index}>
            <div className="text-white w-full font-bold text-center text-md mb-5 lg:mb-4">
              Choose {item.name}
            </div>
            <div className="flex gap-2 lg:gap-4 justify-center">
              {options.map((subItem, subIndex) => {
                return (
                  <div
                    className="relative rounded-3xl overflow-hidden"
                    key={subItem.name + subIndex}
                    onClick={() => {
                      changeBody(item.type, subItem.name);
                    }}
                  >
                    <div className={getClass(item, subItem)}>
                      <img
                        alt={subItem.name}
                        className="object-cover cursor-pointer rounded-[10px] lg:rounded-3xl w-[120px] lg:w-full h-[120px] lg:h-full"
                        src={assetUrl(
                          baseUrl + item.type + "/" + subItem.image
                        )}
                      />
                      <div className="absolute flex items-center justify-center whitespace-nowrap bottom-2 left-1/2 right-1/2 cursor-pointer">
                        <div className="text-white font-bold text-sm lg:text-[16px] leading-[15px] bg-[#1C1C1C] bg-opacity-60 rounded-full py-1.5 lg:py-1.5 px-1.5 lg:px-3">
                          {subItem.name}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ChooseBodyType;
