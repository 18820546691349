import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { Button } from "../../../Components/button";
import { IoSend } from "react-icons/io5";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const waifu = [
  {
    name: "Akane Yamamoto",
    image: "/ai-girlfriend-images/waifu/akane_face.jpeg",
    full_image: "/ai-girlfriend-images/waifu/akane.jpeg",
    complimentary_message: "I am missing you Baby....",
    chats: [
      {
        id: 1,
        user: "Hey! How are you doing today?",
        ai: "I am doing great! How about you?",
        type: "text",
      },
      {
        id: 2,
        user: "how's your day been",
        ai: "fine but i really missed you a lot",
        type: "text",
      },
      {
        id: 3,
        user: "Can you send me your image?",
        ai: "Sure! Here is my image",
        image: "/ai-girlfriend-images/waifu/akane.jpeg",
        type: "image",
      },
    ],
    description:
      "Akane likes to wear short clothes in public and flash them...",
    age: 22,
  },
  {
    name: "Yumi Nakamura",
    image: "/ai-girlfriend-images/waifu/yumi_face.jpeg",
    full_image: "/ai-girlfriend-images/waifu/yumi.jpeg",
    complimentary_message: "Hey, Darling I am waiting for you...",
    chats: [
      {
        id: 1,
        user: "Hey, miss you",
        ai: "Aww, you're so sweet! Just here, waiting to chat with you. 😊",
        type: "text",
      },
      {
        id: 2,
        user: "I love you!",
        ai: "Of course! I also love you and you have the sweetest words. You're my favorite person to talk to. 💕",
        type: "text",
      },
      {
        id: 3,
        user: "Cheer me up!",
        ai: "Oh, I'm here for you! Let me share a cute image of myself to make you smile.😄",
        image: "/ai-girlfriend-images/waifu/yumi.jpeg",

        type: "image",
      },
    ],
    description:
      "Blonde hair, gray eyes, and a Huge set of boobs make yumi special...",
    age: 22,
  },
  {
    name: "Hikari Tanaka",
    image: "/ai-girlfriend-images/waifu/hikari_face.jpeg",
    complimentary_message: "Hey, i am at school right now chat with me...",

    chats: [
      {
        id: 1,
        user: "Hi, thinking of you",
        ai: "That's so sweet! I've been eagerly waiting to talk to you. 😊",
        type: "text",
      },
      {
        id: 2,
        user: "You're amazing",
        ai: "You always know how to make me smile. You're incredible, and I'm here just for you! 💖",
        type: "text",
      },
      {
        id: 3,
        user: "Need a smile",
        ai: "I've got just the thing! How about a sexy image of me?  🤤",
        image: "/ai-girlfriend-images/waifu/hikari.jpeg",
        type: "image",
      },
    ],

    full_image: "/ai-girlfriend-images/waifu/hikari.jpeg",
    description: "Hikari likes to tease her looks and she is a student at ...",
    age: 22,
  },
];

const feature = {
  name: "Rewards",
  name_prefix: "The more you chat",
  name_suffix: "The more you get",

  description: "Description",
  description_one:
    "AI Models are always so humble to give you rewards for chatting with them, the more you chat with them the more rewards you get, you can use these rewards to unlock new features, new waifus, and many more. ",
  description_two:
    "These Rewards can contain new clothing for your waifus, new accessories, new backgrounds, and many more. You can also generate images of your waifus using these rewards.",

  imageSrc: "/ai-girlfriend-images/three-feature3.png",
  button_redirect: "https://pornhaven.ai/girlfriend",
  imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ExperienceOurAI({ allRedirect }) {
  const [currentWaifu, setCurrentWaifu] = useState(waifu[0]);
  const [counter, setCounter] = useState(0);

  function waifuSelector(waifu) {
    setCurrentWaifu(waifu);
    setCounter(counter + 1);
  }
  return (
    <div className="mx-auto max-w-7xl">
      <h2 className="text-center text-3xl font-bold tracking-tight  sm:text-4xl">
        <span className="text-primary">Experience</span> our AI
      </h2>
      <div className="mt-10 flex justify-center gap-4 md:gap-20 ">
        {waifu.map((waifu, index) => (
          <div key={waifu.name} onClick={() => waifuSelector(waifu)}>
            <img
              src={waifu.image}
              alt={waifu.name}
              className={`mx-auto h-[70px] w-[70px] rounded-full object-cover object-top shadow-lg md:h-[150px] md:w-[150px] ${
                currentWaifu.name == waifu.name &&
                "ring-4 ring-primary md:ring-8"
              } `}
            />
            <div className="mt-4">
              <h3 className="text-center font-bold md:text-lg">{waifu.name}</h3>
              {currentWaifu.name == waifu.name && (
                <Link
                  to={allRedirect}
                  className="relative -z-10 flex h-24 w-full items-center justify-center "
                >
                  <svg
                    className="absolute  -top-44 h-48 w-full blur-3xl"
                    viewBox="0 0 100 100"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      r="50"
                      stroke="#f43f5e"
                      strokeWidth="20"
                      fill="transparent"
                    />
                  </svg>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
      <Simulator
        currentWaifu={currentWaifu}
        counter={counter}
        allRedirect={allRedirect}
      />
      <div className="mt-20">
        {" "}
        <div
          key={feature.name}
          className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
        >
          <div
            className={
              "lg:col-start-1 mt-6 lg:col-span-6 lg:row-start-1 lg:mt-0"
            }
          >
            <h3 className="mx-auto max-w-lg text-center text-3xl font-bold text-white/90">
              {feature.name_prefix}{" "}
              <span className="text-primary">{feature.name_suffix}</span>
            </h3>
            <p className="mx-auto mt-6 max-w-xl text-center text-base text-white/60">
              {feature.description_one}
            </p>
            <p className="mx-auto mt-4 max-w-xl text-center text-base text-white/60">
              {feature.description_two}
            </p>

            <Link to={allRedirect} className="flex justify-center">
              <Button className="mx-auto mt-4 text-lg font-bold">
                Try for free
              </Button>
            </Link>
          </div>
          <div
            className={"lg:col-start-7 flex-auto lg:col-span-6 lg:row-start-1"}
          >
            <Link
              to={allRedirect}
              className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg "
            >
              <img
                src={feature.imageSrc}
                alt={feature.imageAlt}
                className="object-cover object-center mx-auto"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Simulator({ currentWaifu, counter, allRedirect }) {
  return (
    <div className="flex w-full flex-row overflow-hidden rounded-xl ring-4 ring-zinc-500 -mt-10">
      <div className="w-full md:w-8/12">
        <div className="flex h-[680px] flex-col">
          <SimulatorHeader currentWaifu={currentWaifu} />
          <div className="relative h-full ">
            <SimulatorChat
              currentWaifu={currentWaifu}
              counter={counter}
              allRedirect={allRedirect}
            />
          </div>
        </div>
      </div>
      <div className="relative hidden w-4/12 md:inline-block">
        <SimulatorSidebar currentWaifu={currentWaifu} />
      </div>
    </div>
  );
}

function SimulatorHeader({ currentWaifu }) {
  return (
    <div className="bg-zinc-800 p-3 px-6 text-lg font-bold">
      <div className="flex justify-between">
        <div className="flex w-fit gap-6">
          <img className="w-12 rounded-full" src={currentWaifu.image}></img>
          <p className="my-auto"> {currentWaifu.name}</p>
        </div>
        <div className="flex w-fit gap-1">
          <GoDotFill className="my-auto animate-pulse pb-1 text-xl text-green-500" />
          <p className="my-auto text-base font-normal text-white/70">Online</p>
        </div>
      </div>
    </div>
  );
}

function SimulatorSidebar({ currentWaifu }) {
  return (
    <>
      <img
        className="h-[680px] w-full object-cover  object-top"
        src={currentWaifu.full_image}
      ></img>
      <div className="absolute bottom-0 h-full w-full bg-gradient-to-t from-black to-transparent p-3">
        <div className="flex h-full flex-col justify-end">
          {" "}
          <p className=" mb-2 text-xl font-bold"> {currentWaifu.name}</p>
          <p className="text-white/60">{currentWaifu.description}</p>
          <p className="text-white/60">Age: {currentWaifu.age}</p>
        </div>
      </div>
    </>
  );
}

function SimulatorChat({ currentWaifu, counter, allRedirect }) {
  const [messages, setMessages] = useState([]);
  const [options, setOptions] = useState([...currentWaifu.chats]);

  useEffect(() => {
    setMessages([]);
    setOptions([...currentWaifu.chats]);
  }, [counter]);

  const handleOptionClick = (selectedChat) => {
    const newMessages = [
      ...messages,
      { type: "user", content: selectedChat.user },
    ];

    if (selectedChat.type === "image") {
      // Add separate messages for text and image
      newMessages.push({ type: "text", content: selectedChat.ai });
      newMessages.push({ type: "image", content: selectedChat.image });
    } else {
      // For non-image messages
      newMessages.push({ type: selectedChat.type, content: selectedChat.ai });
    }

    setMessages(newMessages);
    setOptions(options.filter((chat) => chat.id !== selectedChat.id));
  };

  return (
    <div className="chat-simulator relative h-full  overflow-auto">
      <div className="messages h-[600px] overflow-auto p-3 pb-40">
        <div className="w-fit rounded-lg rounded-bl-none bg-zinc-700 p-2 px-5">
          {currentWaifu.complimentary_message}
        </div>

        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex w-full ${
              msg.type === "user" ? "justify-end" : "justify-start"
            } my-2`}
          >
            <div
              className={`message ${
                msg.type === "user"
                  ? "rounded-br-none bg-primary"
                  : "rounded-bl-none bg-zinc-700"
              } w-fit rounded-lg  p-2 px-5`}
            >
              {msg.type === "text" || msg.type === "user" ? (
                <p className="h-fit">{msg.content}</p>
              ) : msg.type === "image" ? (
                <div className="py-2">
                  {/* {msg.content} */}
                  <img
                    className="w-40 rounded-lg "
                    src={msg.content}
                    alt="Chat Image"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <Link
        to={allRedirect}
        className="absolute bottom-0 z-50 h-[200px]  w-full   gap-4 overflow-hidden   xl:h-[118px]"
      >
        <div className="w-ful mt-2 flex flex-wrap items-center  justify-center gap-3 p-1">
          <Button
            variant="secondary"
            className="chat-option !w-0 rounded-lg  bg-rose-500/30 px-0 text-lg hover:bg-rose-500/20"
          ></Button>

          {options.map((chat) => (
            <Button
              variant="secondary"
              key={chat.id}
              onClick={() => handleOptionClick(chat)}
              className="chat-option rounded-lg bg-rose-500/30 text-lg hover:bg-rose-500/20"
            >
              {chat.user}
            </Button>
          ))}
        </div>
      </Link>

      <div className=" absolute bottom-0 w-full gap-4 cursor-pointer overflow-hidden bg-zinc-800">
        <div className="relative">
          <div className=" flex h-full w-full gap-4 p-3 blur-sm">
            <input
              className="h-10 w-full rounded-lg border bg-zinc-700 p-2 text-white/70"
              type="text"
              disabled
              placeholder="Type a message..."
            ></input>
            <Button size="icon" className="bg-primary">
              <IoSend className="text-xl" />
            </Button>
          </div>
          <div className="absolute bottom-4 my-auto flex  w-full items-center justify-center gap-3">
            <FaLock className="my-auto  text-primary" />
            Get amazing features
            <Link to={allRedirect} className="text-primary">
              Start for free
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
