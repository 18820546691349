const Header = ({ step }) => {
  const list = ["Style", "Origin", "Body", "Face", "Soul", "Summary"];
  const getClass = (index) => {
    if (step > index) {
      return "text-xxs text-white font-semibold mr-2 border-2 rounded-full bg-[#F96C84] border-[#F96C84] px-2 py-[1px]";
    }
    if (step === index) {
      return "text-xxs text-white font-semibold mr-2 border-2 rounded-full border-[#F96C84] px-2 py-[1px]";
    }
    return "text-xxs text-white font-semibold mr-2 border-2 rounded-full border-[#807E8B] px-2 py-[1px]";
  };
  return (
    <div className="flex justify-around bg-[#1c1c1c] h-16">
      {list.map((item, index) => {
        return (
          <div
            className=" text-white flex items-center justify-center"
            key={index}
          >
            <span className={getClass(index)}>{index + 1}</span>
            <span className="hidden lg:flex text-white font-bold">{item}</span>
          </div>
        );
      })}
    </div>
  );
};
export default Header;
