import { Link } from "react-router-dom";
import { Button } from "../../../Components/button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Features({ allRedirect }) {
  const features = [
    {
      name: "Image Generator",
      name_prefix: "Make your Dreams come",
      name_suffix: " True",
      description_one:
        "Use tags to generate images of your favourite anime characters, Our AI model is trained on thousands of images to generate the best images for you.",
      description_two:
        "There are so many categories of tags to choose from, you can choose from categories like sexy, cute, hot, and many more. You can also use advanced settings to generate images of your waifus.",
      imageSrc: "/ai-girlfriend-imageshttps://pornhaven.ai/girlfriends.png",
      buttonText: "Generate for free",
      button_reirect: allRedirect,
      imageAlt:
        "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
    },
    {
      name: "Image Generation",
      name_prefix: "Use Prompts to",
      name_suffix: "Go Beyond",

      description:
        "Use prompts to masterfully craft an image that is as unique as you are. Our AI generator can create the perfect image for you, based on your unique digital desires and needs.",
      description_one:
        "Use prompts to masterfully craft an image that is as unique as you are. Our AI generator can create the perfect image for you, based on your unique digital desires and needs.",
      description_two:
        "You can also use negative prompts to generate images that are not what you want, this will help you to get the perfect image for you. ",
      buttonText: "Generate for free",
      button_reirect: allRedirect,
      imageSrc: "/ai-girlfriend-images/prompt-generate.png",
      imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
    },

    {
      name: "Chatting",
      name_prefix: "Discover our",
      name_suffix: "AI Sexting Chat",
      description_one:
        "THe waifus hahve a heart of their own and they are always ready to listen to you, no matter what you have to say. They are always there to support you, comfort you, and make you feel loved. They are the perfect companions for those lonely nights when you need someone to talk to.",
      description_two:
        "You will feel loved and cared after talking sometine with our AI Waifus, make them your own or create one of your own and start chatting with them. ",
      imageSrc: "/ai-girlfriend-images/one-feature2.png",
      buttonText: "Start Chatting",
      button_reirect: allRedirect,
      imageAlt:
        "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
    },
    {
      name: "Image Generation",
      name_prefix: "Make your Waifu to",
      name_suffix: "Generate Images",

      description:
        "We design every detail with the best materials and finishes. This laptop sleeve features durable canvas with double-stitched construction, a felt interior, and a high quality zipper that hold up to daily use.",
      description_one:
        "Our AI-generated images are as unique as you are, and they’re designed to cater to your every whim. Whether you’re looking for a sexy anime, a cute anime, or a hot anime, our AI generator can create the perfect image for you.",
      description_two:
        "Longing for a petite, kawaii anime schoolgirl? Thirsting over a burly and buff yet deeply caring man? No matter what you’re looking for, our AI generator can sensually shape your experience based on your unique digital desires and needs into perfect images.",

      imageSrc: "/ai-girlfriend-images/two-feature2.png",
      buttonText: "Generate for free",
      button_reirect: allRedirect,
      imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
    },

    // {
    //   name: "Rewards",
    //   name_prefix: "The more you chat",
    //   name_suffix: "The more you get",

    //   description: "Description",
    //   description_one:
    //     "AI Models are always so humble to give you rewards for chatting with them, the more you chat with them the more rewards you get, you can use these rewards to unlock new features, new waifus, and many more. ",
    //   description_two:
    //     "These Rewards can contain new clothing for your waifus, new accessories, new backgrounds, and many more. You can also generate images of your waifus using these rewards.",

    //   imageSrc: "/ai-girlfriend-images/three-feature3.png",
    //   imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
    // },
  ];

  return (
    <div className="">
      <div className="mx-auto max-w-2xl py-24  sm:py-32 lg:max-w-7xl ">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight  sm:text-4xl">
            Our Features
          </h2>
          <p className="mt-4 text-white/50">
            Our smartest AI hentai lets you chat with your favourite anime
            character and also lets you generate images, you can also create
            your own waifu and start chatting with them.
          </p>
        </div>

        <div className="mt-16 space-y-16">
          {features.map((feature, featureIdx) => (
            <div
              key={feature.name}
              className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
            >
              <div
                className={classNames(
                  featureIdx % 2 === 0 ? "lg:col-start-1" : "lg:col-start-7",
                  "mt-6 lg:col-span-6 lg:row-start-1 lg:mt-0"
                )}
              >
                <h3 className="mx-auto max-w-lg text-center text-3xl font-bold text-white/90">
                  {feature.name_prefix}{" "}
                  <span className="text-primary">{feature.name_suffix}</span>
                </h3>
                <p className="mx-auto mt-6 max-w-xl text-center text-base text-white/60">
                  {feature.description_one}
                </p>
                <p className="mx-auto mt-4 max-w-xl text-center text-base text-white/60">
                  {feature.description_two}
                </p>

                <Link
                  to={feature.button_reirect}
                  className="flex justify-center"
                >
                  <Button className="mx-auto mt-4 text-lg font-bold">
                    {feature.buttonText}
                  </Button>
                </Link>
              </div>
              <div
                className={classNames(
                  featureIdx % 2 === 0 ? "lg:col-start-7" : "lg:col-start-1",
                  "flex-auto lg:col-span-6 lg:row-start-1"
                )}
              >
                <Link
                  to={feature.button_reirect}
                  className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg "
                >
                  <img
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className="object-cover object-center"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
