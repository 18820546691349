import React from "react";
import { Button } from "../../../Components/button";
import { LuDot, LuEyeOff, LuInfo, LuSparkles } from "react-icons/lu";
import TextareaAutosize from "react-textarea-autosize";
import { FaCircle } from "react-icons/fa";

/* eslint-disable jsx-a11y/anchor-is-valid */
const heroTabButtons = [
  {
    id: 1,
    name: "Novel",
    image:
      "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fa854f716b37d256a41f8642118b9f76c.cdn.bubble.io%2Ff1703091379381x749493229708395600%2Ffairy-tale-book%25201.png?w=48&h=48&auto=compress&dpr=1&fit=max",
  },
  {
    id: 2,
    name: "Poem",
    image:
      "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fa854f716b37d256a41f8642118b9f76c.cdn.bubble.io%2Ff1703091410697x464037980560340900%2Ffire%25201.png?w=48&h=48&auto=compress&dpr=1&fit=max",
  },
  {
    id: 3,
    name: "Story",
    image:
      "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fa854f716b37d256a41f8642118b9f76c.cdn.bubble.io%2Ff1703091417259x772736372047532900%2Fchat.png?w=48&h=48&auto=compress&dpr=1&fit=max",
  },
];
export default function Hero() {
  const [activeTab, setActiveTab] = React.useState(1);
  return (
    <div className="relative">
      <main className="lg:relative">
        <div className="mx-auto w-full flex flex-col xl:flex-row max-w-3xl xl:max-w-screen-2xl pb-20 pt-16  xl:px-20 text-center lg:py-28 py-10 xl:text-left">
          <div className="px-6 sm:px-8 w-full my-auto  text-center md:text-start py-10">
            <h1 className="text-5xl font-black tracking-normal  text-foreground md:text-7xl">
              GENERATE PERSONALISED STORIES ABOUT
              <br />
              <span className="font-thin relative text-primary text-6xl sm:text-7xl md:text-[103px] skpukok double-outline-text">
                ANYTHING
              </span>
            </h1>
            <p className="mx-auto mt-3 ms-4 text-base text-foreground/80 sm:text-base md:mt-5 md:max-w-base">
              Instantly create captivation fiction with our AI story writer.
            </p>
          </div>
          <div className="px-4 sm:px-8 w-full">
            <div className=" rounded-3xl h-full bg-gradient-to-tr from-purple-800 to-rose-200 p-[1.5px]">
              <div className="h-full w-full bg-[#25262d] rounded-[24px]">
                <div className="flex gap-2 justify-between flex-col h-full">
                  <div className="p-6 flex gap-2 border-b">
                    {heroTabButtons.map((tab) => (
                      <Button
                        key={tab.id}
                        type="button"
                        variant={
                          activeTab === tab.id
                            ? "secondary_light"
                            : "transparent_ghost"
                        }
                        size="xxl"
                        className="w-fit rounded-2xl"
                        onClick={() => setActiveTab(tab.id)}
                        //   className="flex items-center justify-center gap-2 w-full h-12 text-foreground/80 bg-transparent rounded-lg hover:bg-foreground/10"
                      >
                        <img
                          src={tab.image}
                          alt={tab.name}
                          className="size-10"
                        />
                        <span>{tab.name}</span>
                      </Button>
                    ))}
                  </div>
                  <div className="p-6 py-10 space-y-4">
                    <div className="flex gal-4 justify-between">
                      <p>Describe what’s on your mind</p>
                      <LuInfo size={24} />
                    </div>
                    <TextareaAutosize
                      className="w-full h-48 text-foreground/80 bg-transparent  rounded-lg focus-within:outline-none outline-none resize-none"
                      placeholder="e.g. A desert farm boy unveils secret powers. Joined by a fearless princess, a rogue pilot, and an ancient wizard, he rallies against the vile Galactic Empire in this Sci-Fi fantasy."
                    ></TextareaAutosize>
                  </div>
                  <div className="flex flex-col md:flex-row gap-2 justify-between p-6 ">
                    <div className="flex justify-center w-fit items-center rounded-2xl bg-foreground/5">
                      <Button
                        variant="secondary_light"
                        size="xxl"
                        className="rounded-2xl"
                      >
                        <FaCircle size={12} className="text-primary " />
                        Live
                      </Button>
                      <Button
                        variant="transparent_ghost"
                        size="xxl"
                        className="hover:bg-transparent rounded-2xl"
                      >
                        <LuEyeOff size={18} className="text-foreground/20 " />
                        Private
                      </Button>
                    </div>
                    <Button size="xxl" className=" rounded-2xl btn">
                      Generate my story free <LuSparkles size={24} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
