import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "./accordion";
import React, { useState } from "react";
const faqs = [
  {
    id: "faq2",
    question: "Can I customize the appearance of my AI anime character?",
    answer: (
      <p>
        Absolutely! Our AI anime characters are fully customizable. You can
        adjust features like hair color, eye color, outfit, and more to create
        your ideal character. Our technology ensures that every detail is
        rendered with high-quality graphics for a truly immersive experience.
      </p>
    ),
  },
  {
    id: "faq3",
    question:
      "How does the AI Hentai adapt to my personal desires and fantasies?",
    answer: (
      <p>
        Our AI is designed to understand and adapt to your unique desires and
        fantasies. Through advanced learning algorithms, the AI picks up on your
        preferences and continuously evolves to better suit your tastes,
        ensuring a deeply personal and fulfilling experience.
      </p>
    ),
  },
  {
    id: "faq4",
    question:
      "Are there any limitations on the types of fantasies the AI Hentai can fulfill?",
    answer: (
      <p>
        While our AI is highly versatile, we maintain strict adherence to
        ethical standards and legality. As such, certain types of content and
        requests that are deemed illegal, harmful, or violate our community
        guidelines are not supported. We prioritize the safety and well-being of
        all users.
      </p>
    ),
  },
  {
    id: "faq5",
    question: "How is my privacy protected when using this service?",
    answer: (
      <p>
        Your privacy is our top priority. All interactions with your AI anime
        character are encrypted and securely stored. We do not share your data
        with third parties and employ robust security measures to protect your
        personal information and usage details.
      </p>
    ),
  },
  {
    id: "faq6",
    question: "What if I want to change something about my AI waifu?",
    answer: (
      <p>
        You can modify your AI waifu characteristics at any time. Our platform
        offers flexibility in adjusting her appearance, personality, and other
        traits, ensuring she always aligns with your evolving preferences.
      </p>
    ),
  },
];

function Faq() {
  return (
    <div className="pb- mx-auto max-w-7xl  py-24 pb-0 pt-20">
      <div>
        <div className="mx-auto max-w-7xl ">
          <div className="mx-auto max-w-2xl sm:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Frequently Asked{" "}
              <span className="text-primary-muted">Questions</span>
            </p>
          </div>
        </div>
        <div className="mt-5">
          <Accordion type="multiple" collapsible>
            {faqs.map((faq) => (
              <AccordionItem key={faq.id} value={faq.id} className={"mt-4"}>
                <AccordionTrigger>{faq.question}</AccordionTrigger>
                <AccordionContent>{faq.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Faq;
