import root from "react-shadow";
// eslint-disable-next-line import/no-webpack-loader-syntax
import css from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./App.css';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { useEffect, useState } from "react";
import ChooseHairStyle from "../../Templates/ChooseHairStyle";
import ChooseStyle from "../../Templates/ChooseStyle";
import ChooseEthnicity from "../../Templates/ChooseEthnicity";
import ChooseBodyType from "../../Templates/ChooseBodyType";
import ChooseVoice from "../../Templates/ChooseVoice";
import Summary from "../../Templates/Summary";
import { landerSettings } from "../../LanderSettings";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { Oval } from "react-loader-spinner";
import { Helmet } from "react-helmet";

export default function TPL01() {
  const initialState = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3001/";
    window.lander = {
      version: "1.0",
      baseUrl: baseUrl,
      handler: (data) => {
        console.log(data);
      },
      settings: landerSettings,
    };
  }, []);

  const { isLoading: tokenLoading, data: cmsData } = useQuery({
    queryKey: ["cmsData"],
    queryFn: () => fetchData(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  console.log("🚀 ~ TPL01 ~ cmsData:", cmsData);

  const [step, setStep] = useState(initialState("lander_current_step", 0));
  const [style, setStyle] = useState(initialState("lander_style", false));
  const [ethnicity, setEthnicity] = useState(
    initialState("lander_ethnicity", false)
  );
  const [age, setAge] = useState(initialState("lander_age", 27));
  const [bodyType, setBodyType] = useState(
    initialState("lander_bodyType", false)
  );
  const [bodyBreast, setBodyBreast] = useState(
    initialState("lander_bodyBreast", false)
  );
  const [bodyButt, setBodyButt] = useState(
    initialState("lander_bodyButt", false)
  );
  const [hairStyle, setHairStyle] = useState(
    initialState("lander_hairStyle", false)
  );
  const [hairColor, setHairColor] = useState(
    initialState("lander_hairColor", false)
  );
  const [eyeColor, setEyeColor] = useState(
    initialState("lander_eyeColor", false)
  );
  const [relationship, setRelationship] = useState(
    initialState("lander_relationship", false)
  );
  const [save, setSave] = useState(initialState("lander_save", false));
  const [referralCode, setReferralCode] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    localStorage.setItem("lander_current_step", JSON.stringify(step));
  }, [step]);

  useEffect(() => {
    localStorage.setItem("lander_style", JSON.stringify(style));
  }, [style]);

  useEffect(() => {
    localStorage.setItem("lander_ethnicity", JSON.stringify(ethnicity));
  }, [ethnicity]);

  useEffect(() => {
    localStorage.setItem("lander_age", JSON.stringify(age));
  }, [age]);

  useEffect(() => {
    localStorage.setItem("lander_bodyType", JSON.stringify(bodyType));
  }, [bodyType]);

  useEffect(() => {
    localStorage.setItem("lander_bodyBreast", JSON.stringify(bodyBreast));
  }, [bodyBreast]);

  useEffect(() => {
    localStorage.setItem("lander_bodyButt", JSON.stringify(bodyButt));
  }, [bodyButt]);

  useEffect(() => {
    localStorage.setItem("lander_hairStyle", JSON.stringify(hairStyle));
  }, [hairStyle]);

  useEffect(() => {
    localStorage.setItem("lander_hairColor", JSON.stringify(hairColor));
  }, [hairColor]);

  useEffect(() => {
    localStorage.setItem("lander_eyeColor", JSON.stringify(eyeColor));
  }, [eyeColor]);

  useEffect(() => {
    localStorage.setItem("lander_relationship", JSON.stringify(relationship));
  }, [relationship]);

  useEffect(() => {
    localStorage.setItem("lander_save", JSON.stringify(save));
  }, [save]);

  const changeStep = (newStep) => {
    const validStep = parseInt(newStep, 10);
    if (!isNaN(validStep) && validStep > -1 && validStep < 6) {
      setStep(validStep);
    } else {
      console.error("Step value is out of range.");
    }
  };

  const changeStyle = (_style) => setStyle(_style);
  const changeEthnicity = (_ethnicity) => setEthnicity(_ethnicity);
  const changeAge = (_age) => setAge(_age);
  const changeBody = (_type, _value) => {
    if (_type === "type") setBodyType(_value);
    if (_type === "breast") setBodyBreast(_value);
    if (_type === "butt") setBodyButt(_value);
  };
  const changeHair = (_type, _value) => {
    if (_type === "style") setHairStyle(_value);
    if (_type === "color") setHairColor(_value);
    if (_type === "eye-color") setEyeColor(_value);
  };
  const changeVoice = (_type, _value) => {
    if (_type === "relationship") setRelationship(_value);
  };

  const canNext = () => {
    const conditions = [
      step === 0 && !!style,
      step === 1 && !!ethnicity,
      step === 2 && !!bodyType && !!bodyBreast && !!bodyButt,
      step === 3 && !!hairStyle && !!hairColor && !!eyeColor,
      step === 4 && !!relationship,
    ];
    return conditions[step] || false;
  };

  useEffect(() => {
    if (!cmsData) return;
    const redirect = cmsData.data.final_button.href;
    setRedirectUrl(redirect);

    // Function to extract the referral code from the URL
    const getReferralCode = () => {
      // Check if running in a browser environment
      if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);
        const ref = params.get("ref");
        if (ref) {
          setReferralCode(ref);
          const redirect = cmsData.data.final_button.href + "?ref=" + ref;
          setRedirectUrl(redirect);

          // Optionally, you can store it in localStorage or send it to your backend
          // localStorage.setItem('referralCode', ref);
        }
      }
    };

    getReferralCode();
  }, [cmsData]); // Empty dependency array ensures this runs once on mount

  if (tokenLoading)
    return (
      <root.div className="flex h-screen justify-center items-center">
        <Helmet>
          <link
            rel="icon"
            type="image/svg"
            href="./favicon.svg"
            sizes="16x16"
          />
        </Helmet>

        <div className="font-poppins bg-black-default w-full h-full min-h-screen main-wrapper flex justify-center items-center">
          <Oval
            visible={true}
            height="50"
            width="50"
            color="#fc768a"
            secondaryColor="#fc768a50"
            ariaLabel="tail-spin-loading"
            radius="2"
          />
        </div>
      </root.div>
    );

  return (
    <div className="h-full">
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap"
        rel="stylesheet"
      />
      <style type="text/css">{css}</style>
      <div className="font-poppins bg-black-default w-full min-h-screen pb-10 main-wrapper">
        <Header step={step} />
        <div className="px-4 lg:px-0 mb-16 lg:mb-0">
          {step === 0 && (
            <ChooseStyle
              style={style}
              changeStyle={changeStyle}
              cmsData={cmsData}
            />
          )}
          {step === 1 && (
            <ChooseEthnicity
              style={style}
              ethnicity={ethnicity}
              changeEthnicity={changeEthnicity}
              changeAge={changeAge}
              data={cmsData.data.ethnicity}
            />
          )}
          {step === 2 && (
            <ChooseBodyType
              style={style}
              changeBody={changeBody}
              bodyType={bodyType}
              bodyBreast={bodyBreast}
              bodyButt={bodyButt}
            />
          )}
          {step === 3 && (
            <ChooseHairStyle
              style={style}
              changeHair={changeHair}
              hairStyle={hairStyle}
              hairColor={hairColor}
              eyeColor={eyeColor}
              data={cmsData.data.hair_style}
            />
          )}
          {step === 4 && (
            <ChooseVoice
              style={style}
              changeVoice={changeVoice}
              voice={null}
              relationship={relationship}
            />
          )}
          {step === 5 && (
            <Summary
              step={step}
              style={style}
              ethnicity={ethnicity}
              age={age}
              bodyType={bodyType}
              bodyBreast={bodyBreast}
              bodyButt={bodyButt}
              hairStyle={hairStyle}
              hairColor={hairColor}
              eyeColor={eyeColor}
              voice={null}
              relationship={relationship}
              save={save}
            />
          )}
        </div>
        <Footer
          step={step}
          changeStep={changeStep}
          canNext={canNext}
          changeSave={() => setSave(true)}
          redirectUrl={redirectUrl}
          cmsData={cmsData}
        />
      </div>
    </div>
  );
}

const fetchData = async () => {
  const apiToken = process.env.REACT_APP_STRAPI_ACCESS_TOKEN_SECRET;
  const strapiUrl = process.env.REACT_APP_STRAPI_URL;

  // Construct the query string using qs
  const query = qs.stringify(
    {
      populate: {
        style: {
          populate: ["image"],
        },
        ethnicity: {
          populate: {
            attributes: {
              populate: {
                anime: {
                  populate: [
                    "image",
                    "background_image",
                    "left_image",
                    "center_image",
                    "right_image",
                  ],
                },
                realistic: {
                  populate: [
                    "image",
                    "background_image",
                    "left_image",
                    "center_image",
                    "right_image",
                  ],
                },
              },
            },
          },
        },
        hair_style: {
          populate: {
            attributes: {
              populate: {
                anime: {
                  populate: ["image"],
                },
                realistic: {
                  populate: ["image"],
                },
              },
            },
          },
        },
        final_button: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const response = await fetch(`${strapiUrl}/api/lander-1?${query}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`, // Ensure your API token is correctly set
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Network response was not ok: ${errorData.error.message}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    // You might want to handle the error differently depending on your use case
    return null;
  }
};
