import React, { useState } from "react";
import { mainPayload } from ".";

const JsonStrip = () => {
  const fieldsToRemove = "sizeInBytes";

  // Sample payload (replace this with your actual JSON data)

  const [data, setData] = useState(mainPayload);

  // Function to remove the specified key from the JSON object
  const removeKey = (obj, keyToRemove) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeKey(item, keyToRemove));
    } else if (obj !== null && typeof obj === "object") {
      return Object.keys(obj).reduce((acc, key) => {
        if (key !== keyToRemove) {
          acc[key] = removeKey(obj[key], keyToRemove);
        }
        return acc;
      }, {});
    } else {
      return obj;
    }
  };

  const handleRemoveField = () => {
    const newData = removeKey(data, fieldsToRemove);
    console.log("🚀 ~ handleRemoveField ~ newData:", newData);
    setData(newData);
  };

  return (
    <div>
      <button onClick={handleRemoveField}>Remove field</button>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default JsonStrip;

const payload = {
  name: "File",
  sizeInBytes: 1024,
  metadata: {
    sizeInBytes: 2048,
    type: "document",
    tags: [{ name: "urgent", sizeInBytes: 512 }, { name: "confidential" }],
  },
};
