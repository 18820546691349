import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../Components/button";

export default function Banner() {
  return (
    <div className="bg-primary max-w-screen">
      <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center">
            <span className="flex rounded-lg bg-rose-800 p-2">
              <MegaphoneIcon
                aria-hidden="true"
                className="h-6 w-6 text-foreground"
              />
            </span>
            <p className="ml-3 flex-shrink truncate font-medium text-foreground">
              <span className=" hidden sm:inline-block ">
                Check out Tooni, the AI comic generator
              </span>

              <span className="sm:hidden text-sm font-bold whitespace-break-spaces">
                Try Tooni, our new comic generator
              </span>
            </p>
          </div>
          <div className="flex-shrink-0 order-2 sm:mt-0 w-auto">
            <Button href="#" variant="white" className="hidden sm:flex">
              Learn more
            </Button>
            <Button size="sm" href="#" variant="white" className="sm:hidden">
              Try it!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
