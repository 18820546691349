export const storiesData = [
  {
    _version: 424098515,
    found: true,
    _source: {
      "Modified Date": 1728034823626,
      "Created Date": 1713700879279,
      "Created By":
        "1348695171700984260__LOOKUP__1713700502554x726415339042268900",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1713700554354x286922160073605120",
      livestatus_text: "Offline",
      score_number: 55,
      selectedscenenumberforlive_number: 3,
      lastupdatedtime_date: 1713722552636,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 12083,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/75865ebe-f0b0-4868-1519-00a5faadfa00/public",
      description_text:
        "A gay guy in his 20s is put under chastity by his friend. Even though he thought that the friend was a straight guy, the relationship will get physical as he hold the lock to the cage, and refuses to unlock him for a very long time.",
      title_text: "Locked Desires Unraveled",
      isnsfw_boolean: true,
      username_text: "jklarik27",
      _id: "1713700871791x106084029510451200",
      _version: 424098515,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1713700871791x106084029510451200",
  },
  {
    _version: 424188466,
    found: true,
    _source: {
      _id: "1714407383707x339526882667276800",
      "Modified Date": 1728045998677,
      "Created Date": 1714407383710,
      "Created By":
        "1348695171700984260__LOOKUP__1714406715920x712182590032506200",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1714407383471x149260622502559740",
      livestatus_text: "Active",
      score_number: 203,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1714407999711,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 8630,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/829712c3-98e6-4d5c-a4fe-7b5eeb02f100/cover",
      description_text:
        "a successful female CEO is fired from her position, humiliated amongst the office staff, then gangbanged by all the men in the office, while the women watch in shock at the brutality. She is stripped naked, clothes torn off, fucked in all holes and covered in cum. Then walked out stark naked, where she walks back to her home, ridiculed by people watching her state",
      title_text: "Downfall of the Corporation Whore - Remix - Remix",
      isnsfw_boolean: true,
      username_text: "intertribalrecord",
      _type: "custom.prostorylivestatus",
      _version: 424188466,
    },
    _type: "custom.prostorylivestatus",
    _id: "1714407383707x339526882667276800",
  },
  {
    _version: 424171588,
    found: true,
    _source: {
      "Modified Date": 1728044045152,
      "Created Date": 1712877814359,
      "Created By":
        "1348695171700984260__LOOKUP__1712877141413x645572632709304600",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1712877689669x789493702312591400",
      livestatus_text: "Offline",
      score_number: 79,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1712878559016,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 3028,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/e4a3dd94-5084-4736-39b3-60ed9fba7a00/public",
      description_text:
        "Michelle, a man who turned into a hot teenage girl, while horrified at her transformation has new urges she hasn't considered, travels with her lifelong friend Mark, who tries to help his friend despite having a weakness for her sexy legs and perky breasts.  At the San Diego beach, they deal with the awkward turned hot romance that followed",
      title_text: "Teen Beach Transformation",
      isnsfw_boolean: true,
      username_text: "wanderingwriter",
      _id: "1712877804298x922741712394911700",
      _version: 424171588,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1712877804298x922741712394911700",
  },
  {
    _version: 424165148,
    found: true,
    _source: {
      "Modified Date": 1728043303675,
      "Created Date": 1713508523463,
      "Created By":
        "1348695171700984260__LOOKUP__1677268726445x249625822309164740",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1713506090681x600090214769623000",
      livestatus_text: "Active",
      score_number: 223,
      selectedscenenumberforlive_number: 6,
      writercurrentaction_text: "Writing",
      lastupdatedtime_date: 1713587828975,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 172801,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/748eff07-ebba-41dd-0a9c-a71a1951d400/public",
      description_text:
        "Brian wakes up transforming into a mega busty blonde bimbo named Candi. She realizes she used to be a man bust also loves her supple body and realizes she needs cocks and cum. She goes out and enjoys he new bimbo lifestyle as she realizes her new life is to simply pleasure as many men as possible and look like a mega slut.",
      title_text: "Transformation To A Bimbo Pleasure Machine",
      isnsfw_boolean: true,
      username_text: "rodimus",
      _id: "1713508510495x100608082081480700",
      _version: 424165148,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1713508510495x100608082081480700",
  },
  {
    _version: 424141339,
    found: true,
    _source: {
      "Modified Date": 1728040545544,
      "Created Date": 1708430971269,
      "Created By":
        "1348695171700984260__LOOKUP__1708429825689x666781053227224000",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1708429954313x256567354290339840",
      livestatus_text: "Offline",
      score_number: 32,
      selectedscenenumberforlive_number: 3,
      writercurrentaction_text: "Writing",
      lastupdatedtime_date: 1712309349210,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 120870,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/f19b0ac4-8168-452e-74a2-b7ee8d5a2f00/public",
      description_text:
        "My roommates smoking hot girlfriend, Stacey, accidentally discovers I have a huge cock. Against her better judgement she decides she needs to try my cock out for herself. A hot steamy affair ensues.",
      title_text: "Temptation Unveiled",
      isnsfw_boolean: true,
      username_text: "tyrone99",
      _id: "1708430964651x423404748741541900",
      _version: 424141339,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1708430964651x423404748741541900",
  },
  {
    _version: 424031863,
    found: true,
    _source: {
      "Modified Date": 1728026969456,
      "Created Date": 1704021817870,
      "Created By":
        "1348695171700984260__LOOKUP__1703587638360x821589058406762000",
      comments_list_custom_procommentorreaction: [
        "1348695171700984260__LOOKUP__1704145252861x676877769882206200",
        "1348695171700984260__LOOKUP__1704145257972x542453462771171300",
      ],
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1704021450579x538035159408836600",
      livestatus_text: "Active",
      score_number: 49,
      selectedscenenumberforlive_number: 4,
      lastupdatedtime_date: 1704298435652,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 327568,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/74cba6c0-2d08-4062-181a-8a4f7eeb8f00/public",
      description_text:
        "Karin and John are a couple that wants to explore their BDSM fantasies in in public. They go out for a walk in the wood where they stop at a bench. At the bench the play their BDSM games. Karin does not know about her husbands fantasies to be dominatied until he confesses this her while sitting on the bench. Suddenly an old friend of Karin comes acoross and joins their play. Both women get very dominant and humiliate John as he has always dreamed.",
      title_text: "Desires in public",
      isnsfw_boolean: true,
      tagsstring_text: "erotic, erotica, female",
      username_text: "bigjames",
      _id: "1704021812907x349819370432102400",
      _version: 424031863,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1704021812907x349819370432102400",
  },
  {
    _version: 424059508,
    found: true,
    _source: {
      "Modified Date": 1728029677891,
      "Created Date": 1715805611601,
      "Created By":
        "1348695171700984260__LOOKUP__1714073674352x922075409661819800",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1715805321641x675046602348953600",
      livestatus_text: "Offline",
      score_number: 43,
      selectedscenenumberforlive_number: 4,
      lastupdatedtime_date: 1715836286540,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 24503,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/16046974-7086-4c30-8e28-69fa6ab1fe00/cover",
      description_text:
        "Write an creative CMNF Story where one women is gettting the whole day naked on different locations. It should also be a little funny. ",
      title_text: "A Naughty Nude Adventure",
      isnsfw_boolean: true,
      username_text: "greaterstories",
      _id: "1715805598651x295860762697269250",
      _version: 424059508,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1715805598651x295860762697269250",
  },
  {
    _version: 424156566,
    found: true,
    _source: {
      "Modified Date": 1728042241540,
      "Created Date": 1710467888043,
      "Created By":
        "1348695171700984260__LOOKUP__1710390964920x925245981703645800",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1710467414507x196001456670638100",
      livestatus_text: "Offline",
      score_number: 23,
      selectedscenenumberforlive_number: 3,
      lastupdatedtime_date: 1710650531374,
      potentially_high_risk_boolean: true,
      numberofcharacters_number: 112752,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/1bdc4380-a3ee-4876-176d-97bf6e739500/public",
      description_text:
        "Jesse is a buxom redheaded wife that has always been faithful to her husband.  She is also a successful engineering manager.  She got tipsy at a work party and was photographed making out with an employee.  One of her subordinates used that to blackmail her into increasingly extreme sexual acts. Videos are taken of each situation and are used to blackmail her into the next act. If not, her husband will find out. Husband secretly already knows because he set it all in motion from the start.",
      title_text: "Boundaries Broken",
      isnsfw_boolean: true,
      username_text: "swa76",
      _id: "1710467877523x145473379316531200",
      _version: 424156566,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1710467877523x145473379316531200",
  },
  {
    _version: 424072365,
    found: true,
    _source: {
      "Modified Date": 1728030982691,
      "Created Date": 1715202454625,
      "Created By":
        "1348695171700984260__LOOKUP__1715200956909x967697825946262800",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1715202339882x651899236092739600",
      livestatus_text: "Active",
      score_number: 73,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1715202567146,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 2417,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/bc38df4f-4767-40e3-4d83-c05a4bbf8d00/public",
      description_text:
        "A girl and her teacher fuck so she can get an A. When they are fucking a female walks in and escalates things, tying the student to a table and teasing her with a vibrator.",
      title_text: "An Educational Encounter: Bound & Teased",
      isnsfw_boolean: true,
      username_text: "wildmana53",
      _id: "1715202448258x475478080351109100",
      _version: 424072365,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1715202448258x475478080351109100",
  },
  {
    _version: 424177958,
    found: true,
    _source: {
      _id: "1719404497558x579884344429248500",
      "Modified Date": 1728044692864,
      "Created Date": 1719404506429,
      "Created By":
        "1348695171700984260__LOOKUP__1708282950537x448964709847429440",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1719404194975x486794873337282600",
      livestatus_text: "Active",
      score_number: 83,
      selectedscenenumberforlive_number: 3,
      lastupdatedtime_date: 1719672354050,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 32100,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/95da63e2-ef4a-415f-b4cc-f3c6435d7000/public",
      description_text:
        "My hot blonde trophy wife Kacey is having a hot affir with her black boss Tony.  Tonight is Date Night for the two of them. I lie awake, wondering what they're doing. When Kacey comes home, she strokes me while describing in filthy detail how she submitted to him. ",
      title_text: "Hotwife Confessions",
      isnsfw_boolean: false,
      tagsstring_text: "erotic, male, erotica",
      username_text: "grimysomewhere",
      _type: "custom.prostorylivestatus",
      _version: 424177958,
    },
    _type: "custom.prostorylivestatus",
    _id: "1719404497558x579884344429248500",
  },
  {
    _version: 423916364,
    found: true,
    _source: {
      "Modified Date": 1728015958961,
      "Created Date": 1712877776834,
      "Created By":
        "1348695171700984260__LOOKUP__1678083217383x660565599714566300",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1712877777780x382049033623961600",
      livestatus_text: "Active",
      score_number: 23,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1712884153782,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 65750,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/1c2ff09b-40a8-4c79-83c9-9d062dfc2f00/public",
      description_text:
        "James is a man in his late 20's who suddenly wakes up one day as a bimbo named Missy. She struggles to understand what has happened to her even as she loses her desires and mind to her new persona. Will she ever find out why she changed or will her ditzy little brain lead her down a path she will never come back from?",
      title_text: "Becoming Missy - Remix",
      isnsfw_boolean: true,
      username_text: "yorick9000",
      _id: "1712877776826x752851260859440400",
      _version: 423916364,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1712877776826x752851260859440400",
  },
  {
    _version: 424095266,
    found: true,
    _source: {
      "Modified Date": 1728034332987,
      "Created Date": 1713102217650,
      "Created By":
        "1348695171700984260__LOOKUP__1713101441241x933642469038242000",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1713101889324x883025910387179500",
      livestatus_text: "Offline",
      score_number: 13,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1713102436964,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 3511,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/1f13f426-8a88-4d16-cb3c-58695403d100/public",
      description_text:
        "John - quite tall muscular and handsome man meets one night extremaly tall girl named Julia who is over 2 meters and is much taller than him. Together they explore their height diffrence.",
      title_text: "Towering Desires",
      isnsfw_boolean: true,
      username_text: "trontonetti",
      _id: "1713102212423x914831805739434000",
      _version: 424095266,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1713102212423x914831805739434000",
  },
  {
    _version: 423851721,
    found: true,
    _source: {
      "Modified Date": 1728010296518,
      "Created Date": 1714169692661,
      "Created By":
        "1348695171700984260__LOOKUP__1713371101430x104740343860084690",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1714169341077x494399488898105340",
      livestatus_text: "Offline",
      score_number: 6,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1714170204483,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 4978,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/53c40252-ed3b-4e58-1a89-be3a49182f00/public",
      description_text:
        "An anthropomorphic candlestick and an anthropomorphic Teapot have an intense sexual relationship in a rundown mansion owned by a prince who was turned into an animalistic monster. Oh, and the clock sometimes watches.",
      title_text: "Desires of Inanimate Objects",
      isnsfw_boolean: true,
      username_text: "munchie",
      _id: "1714169690460x115167768460918780",
      _version: 423851721,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1714169690460x115167768460918780",
  },
  {
    _version: 423586439,
    found: true,
    _source: {
      "Modified Date": 1727985700347,
      "Created Date": 1712203530848,
      "Created By":
        "1348695171700984260__LOOKUP__1712203086294x566023179088088960",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1712203281928x571298505523200000",
      livestatus_text: "Active",
      score_number: 25,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1712204504524,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 3719,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/192f12df-9d0f-499d-dba4-9318726cb400/public",
      description_text:
        "Shrek makes love to Fiona in the mud bath. Fart fetish fun ensues....... I want footjobs and mentions of Fiona's womanly smell, and the softness of her plump green flesh.",
      title_text: "Shrek & Fiona's Muddy Liaison",
      isnsfw_boolean: true,
      username_text: "billyranger",
      _id: "1712203525784x820860937240313900",
      _version: 423586439,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1712203525784x820860937240313900",
  },
  {
    _version: 424037963,
    found: true,
    _source: {
      "Modified Date": 1728027672590,
      "Created Date": 1706503434203,
      "Created By":
        "1348695171700984260__LOOKUP__1683606034095x286405403364386600",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1706417985068x308737420057640960",
      livestatus_text: "Active",
      score_number: 53,
      selectedscenenumberforlive_number: 7,
      lastupdatedtime_date: 1709627747883,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 68648,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/84ac6047-884e-4fe1-006a-412a462e4e00/cover",
      description_text:
        "Jeannette drives out to the countryside for an escort job. A new customer has booked her for a gangbang with five black men. She is excited and looks forward to an intense sexual adventure.",
      title_text: "Wild Black Adventure",
      isnsfw_boolean: true,
      username_text: "jeannettexxx",
      _id: "1706503423124x109448116301725700",
      _version: 424037963,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1706503423124x109448116301725700",
  },
  {
    _version: 424033419,
    found: true,
    _source: {
      "Modified Date": 1728027183650,
      "Created Date": 1718008750827,
      "Created By":
        "1348695171700984260__LOOKUP__1702068080825x136679592318978300",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1718008749288x214638437222580220",
      livestatus_text: "Offline",
      score_number: 14,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1718010144967,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 17079,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/17d6ba0d-a25e-4888-865e-bd72eb4c6000/public",
      description_text:
        'Max is a handsome and tall man, but he secretly wishes to know what it\'s like to be a woman, and not just any woman, but a really gorgeous, mega busty one. One day, he finds a strange box filled with tiny and slutty female clothing along with paper that has "magical" instructions to unleash the true inner you. Max tries it and transforms into the sultry, gorgeous & slutty bimbo named Lacey. She is shocked but her new persona soon takes over as she lives her new sexy and slutty life.',
      title_text: "Unleashed Desires: Max's True Self - Remix - Remix",
      isnsfw_boolean: true,
      username_text: "rswfed",
      _id: "1718008750813x989622962544071200",
      _version: 424033419,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1718008750813x989622962544071200",
  },
  {
    _version: 424009162,
    found: true,
    _source: {
      "Modified Date": 1728024599253,
      "Created Date": 1716204030300,
      "Created By":
        "1348695171700984260__LOOKUP__1716203695106x334391713654911040",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1716203902487x886400611980410900",
      livestatus_text: "Offline",
      score_number: 15,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1716204338304,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 3674,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/c0389619-ec26-4381-5c71-606873583f00/cover",
      description_text:
        "First day at my new job. My direct colleague is mean, cold and distant. I can't keep on like this. Today I'm gonna show her who's the boss. And maybe I'll get lucky",
      title_text: "Office Rivalry Unveiled",
      isnsfw_boolean: true,
      username_text: "mugisekai25",
      _id: "1716204020611x549886937635225600",
      _version: 424009162,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1716204020611x549886937635225600",
  },
  {
    _version: 424168758,
    found: true,
    _source: {
      "Modified Date": 1728043733643,
      "Created Date": 1700930964956,
      "Created By":
        "1348695171700984260__LOOKUP__1700930080438x733090837897258000",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1700930211277x429731809812021250",
      livestatus_text: "Offline",
      score_number: 13,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1700931540145,
      potentially_high_risk_boolean: true,
      numberofcharacters_number: 3783,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/46de0341-cff1-4647-13e6-62d6f485ae00/cover",
      description_text:
        "You are Aria, a princess living in the kingdom of Larion. You wake up in a big feather bed and hear clamoring outside your door. A knight rushes in to tell you that\n your father, the king, has been taken captive by a neighboring kingdom and they demand a ransom for his release.\n your father, the king, has been taken captive by a neighboring kingdom and they demand a ransom for his release.\nYou yelp and grip your sheets to your chest, as the knight has just walked in on you sleeping nude",
      title_text: "Nude Royal",
      isnsfw_boolean: true,
      tagsstring_text: "fantasy, battle, hero",
      username_text: "aibitchidgaf",
      _id: "1700930955531x248771971606904830",
      _version: 424168758,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1700930955531x248771971606904830",
  },
  {
    _version: 424088403,
    found: true,
    _source: {
      "Modified Date": 1728033380074,
      "Created Date": 1716286092872,
      "Created By":
        "1348695171700984260__LOOKUP__1716285521742x675684723655825400",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1716286038151x430541807545221100",
      livestatus_text: "Offline",
      score_number: 47,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1716286297748,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 2288,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/b05edded-b5bc-4622-0900-d8856a247100/public",
      description_text:
        "girl fucks dragon to strenghten the bond between rider and dragon",
      title_text: "Dragon Lust Unleashed",
      isnsfw_boolean: true,
      username_text: "pedanticalchair",
      _id: "1716286081624x569321215225495550",
      _version: 424088403,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1716286081624x569321215225495550",
  },
  {
    _version: 422258660,
    found: true,
    _source: {
      "Modified Date": 1727861774468,
      "Created Date": 1710513234650,
      "Created By":
        "1348695171700984260__LOOKUP__1710260895933x894485888177888500",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1710510517871x290420342209380350",
      livestatus_text: "Active",
      score_number: 10,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1710561075687,
      potentially_high_risk_boolean: true,
      numberofcharacters_number: 191101,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/c86ff95a-a24c-4061-7005-8486d88fee00/public",
      description_text:
        "\nAn erotic novel about Chuck, a guy who grew up in a conservative home in Texas. At the age of 18, he started working in construction as a rigger for his uncle.  For five years, he moved heavy materials and equipment around construction worksite.  Working in that condition built his body like a truck, very dense muscles even if he doesn't go to the gym. ",
      title_text: "Gearshift Grind",
      isnsfw_boolean: true,
      username_text: "punkbrutus101",
      _id: "1710513223494x792105817227657200",
      _version: 422258660,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1710513223494x792105817227657200",
  },
  {
    _version: 423923887,
    found: true,
    _source: {
      "Modified Date": 1728016596060,
      "Created Date": 1699102876060,
      "Created By":
        "1348695171700984260__LOOKUP__1699102278894x672165783233851600",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1699102732936x729234122038837200",
      livestatus_text: "Active",
      score_number: 6,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1699103668911,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 4172,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/badd428a-0abd-40d1-5cf0-11be6cee0200/public",
      description_text:
        "Bharat and samya who were in relationship, after some years bharat gets busy in his meetings as he's the leader of famous mafia gang, the intimacy between them was becoming rare then bharat realised this and start preparing a surprise for samya. Her fantasy was to have sex with him in car near the beach with the sound of sea at night under the sky full of stars. Then he surprises her. They both were craving for each other touch and get intimate in the car. The story should erotic and 18+",
      title_text: "Intoxicating Nights Under Starry Skies",
      isnsfw_boolean: true,
      tagsstring_text: "erotic, erotica, sexy",
      username_text: "overbearingpossession",
      _id: "1699102868193x458067426906210300",
      _version: 423923887,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1699102868193x458067426906210300",
  },
  {
    _version: 423783803,
    found: true,
    _source: {
      "Modified Date": 1728003336636,
      "Created Date": 1720011653586,
      "Created By":
        "1348695171700984260__LOOKUP__1716685366880x988545266876417000",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1720011492430x714648928720257000",
      livestatus_text: "Active",
      score_number: 18,
      selectedscenenumberforlive_number: 6,
      writercurrentaction_text: "Writing",
      lastupdatedtime_date: 1726637529596,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 195778,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/5ffead2b-d4c2-481e-3abf-7d914bba2700/public",
      description_text:
        "First day of college as Amy moves in her new apartment, away from home for the first time. She meets her new roommate who is very demanding and has a list of rules Amy must follow, rule number one is that Amy must be nude when she is in the apartment at all times!!!",
      title_text: "Naked Ambitions",
      isnsfw_boolean: true,
      tagsstring_text: "fantasy, witch, warewolf",
      username_text: "jbwatson23",
      _id: "1720011643708x651252281701040100",
      _version: 423783803,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1720011643708x651252281701040100",
  },
  {
    _version: 423634138,
    found: true,
    _source: {
      "Modified Date": 1727989631244,
      "Created Date": 1710790926114,
      "Created By":
        "1348695171700984260__LOOKUP__1700359206187x192393634248288160",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1710790727991x650365903576760300",
      livestatus_text: "Offline",
      score_number: 9,
      selectedscenenumberforlive_number: 5,
      lastupdatedtime_date: 1714012226420,
      potentially_high_risk_boolean: true,
      numberofcharacters_number: 102488,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/ef0831bc-3627-486a-eef3-04dc96e74600/public",
      description_text:
        "Lance, local college superstar is a muscled hunk with a massive dick. He has a supermodel cheerleader girlfriend and is well known locally for his sexual prowess in underground sex videos. He visits the beach with his girlfriend but she gets captured by jealous chubby girls and their leader blackmails him and uses him sexually.",
      title_text: "Caught on the Beach",
      isnsfw_boolean: true,
      username_text: "showeryequipment",
      _id: "1710790914076x592480450187362300",
      _version: 423634138,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1710790914076x592480450187362300",
  },
  {
    _version: 423358816,
    found: true,
    _source: {
      "Modified Date": 1727963602986,
      "Created Date": 1708980433947,
      "Created By":
        "1348695171700984260__LOOKUP__1708979991112x137539671974617580",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1708980258583x310987354655162400",
      livestatus_text: "Active",
      score_number: 11,
      selectedscenenumberforlive_number: 4,
      lastupdatedtime_date: 1710258557101,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 26801,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/9f5e3a82-f704-45c6-937a-a8a392fe3800/public",
      description_text:
        "Dj and Rodney from total drama island fucking in the showers. They meet on bad terms with rodney always starring at dj. Dj assumes it racism but in reality. Rodney thinks dj is beatiful. He's always checking him out in the lockeroom. ",
      title_text: "Island Intrigue: A Total Drama Love Affair",
      isnsfw_boolean: true,
      _id: "1708980424759x102155335771357180",
      _version: 423358816,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1708980424759x102155335771357180",
  },
  {
    _version: 424113773,
    found: true,
    _source: {
      "Modified Date": 1728037131637,
      "Created Date": 1689878066878,
      "Created By":
        "1348695171700984260__LOOKUP__1685575594701x154642100568219040",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1689877892932x878693987409461200",
      livestatus_text: "Active",
      score_number: 58,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1689878287360,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 1773,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/1258ddef-823f-4354-24f8-dd4a02f28600/cover",
      description_text:
        "Two women who hate each other, Clara and Gina, have a kissing contest to settle their grudge ",
      title_text: "Kiss of Anger",
      isnsfw_boolean: true,
      tagsstring_text: "drama, realistic, love",
      username_text: "maybeme",
      _id: "1689878056430x411674215822655500",
      _version: 424113773,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1689878056430x411674215822655500",
  },
  {
    _version: 424037516,
    found: true,
    _source: {
      "Modified Date": 1728027634911,
      "Created Date": 1699615583746,
      "Created By":
        "1348695171700984260__LOOKUP__1699615421362x749201751601534300",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1699615529233x484053711152742400",
      livestatus_text: "Active",
      score_number: 15,
      selectedscenenumberforlive_number: 1,
      writercurrentaction_text: "Writing",
      lastupdatedtime_date: 1699615666538,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 2993,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/8c83718f-7e6a-4965-3997-ea185f957600/cover",
      description_text: "Free use office",
      title_text: "Sexual Awakening in the Corporate Dungeon",
      isnsfw_boolean: true,
      tagsstring_text: "fantasy, warewolf, hero",
      username_text: "janibe2994",
      _id: "1699615574052x912064446350491600",
      _version: 424037516,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1699615574052x912064446350491600",
  },
  {
    _version: 422864002,
    found: true,
    _source: {
      "Modified Date": 1727915919534,
      "Created Date": 1720045570109,
      "Created By":
        "1348695171700984260__LOOKUP__1717439059603x487595281649755500",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1720039146269x494238102928228350",
      livestatus_text: "Offline",
      score_number: 25,
      selectedscenenumberforlive_number: 5,
      lastupdatedtime_date: 1723237410704,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 167324,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/0e72fd50-63f8-46eb-0188-ca2a29556f00/public",
      description_text:
        "A taboo romance between college aged cousins. They are spending time with family at a cabin on the lake. Colton notices his cousin Danielle has developed a very attractive body. He is even more surprised when she starts flirting with him. They must navigate their taboo fling and not get caught. ",
      title_text: "Forbidden Love on the Lake",
      isnsfw_boolean: true,
      tagsstring_text: "romance, badboy, love",
      username_text: "partyboobs",
      _id: "1720045558311x983062821289852900",
      _version: 422864002,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1720045558311x983062821289852900",
  },
  {
    _version: 423552784,
    found: true,
    _source: {
      "Modified Date": 1727982740250,
      "Created Date": 1700511591576,
      "Created By":
        "1348695171700984260__LOOKUP__1700480539889x216029754853463550",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1700481124543x761575708848816100",
      livestatus_text: "Active",
      score_number: 5,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1700917456738,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 33470,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/6cf0d555-0f9e-4808-b920-2ebc55996100/public",
      description_text:
        "Le Texte est écrit en Français :\nUne femme elfique se promène dans une forêt et décide de s'arrêter pour se masturber à l'abris des regards, Malheureusement un Orc qui passe par là, la voit et l'utilise pour son plaisir.",
      title_text: "Voyage érotique dans les bois",
      isnsfw_boolean: true,
      tagsstring_text: "fantasy, elves, magic",
      username_text: "aysahki",
      _id: "1700511584106x908656597024374800",
      _version: 423552784,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1700511584106x908656597024374800",
  },
  {
    _version: 422291641,
    found: true,
    _source: {
      "Modified Date": 1727866135971,
      "Created Date": 1714720222548,
      "Created By":
        "1348695171700984260__LOOKUP__1714279031092x569562623509318700",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1714719682091x373688069902565400",
      livestatus_text: "Active",
      score_number: 37,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1714723095291,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 25813,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/868ffad5-5432-406f-c699-497e1aaae200/cover",
      description_text:
        "Ashlynn and her boyfriend Steve have to spend more time apart than they like. So when Steve is out of town, she streams him a sexy cam show. Usually using a realistic dildo, this time her older neighbor who is always teasing her takes advantage by making a gloryhole. Thanks to the not so great webcam, Steve can't seem to tell the difference! Ashlynn had always told the neighbor she would never cheat without permission.. and Steve was going to give permission without realizing.",
      title_text: "Boyfriend Cucks Himself?",
      isnsfw_boolean: true,
      username_text: "ashlynn227",
      _id: "1714720218583x798540047794307100",
      _version: 422291641,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1714720218583x798540047794307100",
  },
  {
    _version: 420597351,
    found: true,
    _source: {
      "Modified Date": 1727717577306,
      "Created Date": 1715473249479,
      "Created By":
        "1348695171700984260__LOOKUP__1715443662053x957850353707051800",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1715444684225x297126822964363260",
      livestatus_text: "Offline",
      score_number: 11,
      selectedscenenumberforlive_number: 2,
      lastupdatedtime_date: 1715893726334,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 66018,
      selectedcoverart_text:
        "//a854f716b37d256a41f8642118b9f76c.cdn.bubble.io/d233/f1715473219378x544834490517292350/myimage.jpeg",
      description_text:
        "A story that references white briefs as boyish, has women squeeze his testicles, spank him,  make him say things to admit he's submissive to them. Main Character name: Char Age:18",
      title_text: "Surrender of Submission",
      isnsfw_boolean: true,
      username_text: "chronus",
      _id: "1715473242875x703266321808752600",
      _version: 420597351,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1715473242875x703266321808752600",
  },
  {
    _version: 424079405,
    found: true,
    _source: {
      "Modified Date": 1728032037148,
      "Created Date": 1698807672389,
      "Created By":
        "1348695171700984260__LOOKUP__1698807438624x688152503017392800",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1698807539929x659608789612757000",
      livestatus_text: "Active",
      score_number: 9,
      selectedscenenumberforlive_number: 3,
      lastupdatedtime_date: 1698852560537,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 8109,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/20de040d-1f56-495d-4224-8a0087bee600/public",
      description_text:
        "It’s his birthday. They have a small party in a hotel and she has plans for him. She is dresses in a saree with a blouse that's ample cleavage in front and at the back. Teases him the who is sitting on a comfortable chair. Hands tied to a armrest. She does a strip tease and touches herself in front of him. He can only see but can’t do anything. She rubs herself against him. Unzip him, please him,rides him and uncuff him to pound her good until she is sore until next morning",
      title_text: "Birthday Desires In A Hotel Room",
      isnsfw_boolean: true,
      tagsstring_text: "erotic, female, erotica",
      username_text: "coconsciousmood",
      _id: "1698807653886x973879564540313600",
      _version: 424079405,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1698807653886x973879564540313600",
  },
  {
    _version: 423939825,
    found: true,
    _source: {
      "Modified Date": 1728017720768,
      "Created Date": 1714807259926,
      "Created By":
        "1348695171700984260__LOOKUP__1677268726445x249625822309164740",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1714802562585x657720262823510000",
      livestatus_text: "Offline",
      score_number: 114,
      selectedscenenumberforlive_number: 5,
      lastupdatedtime_date: 1715016922201,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 123551,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/d982f5c8-219e-47da-4b40-843198322f00/cover",
      description_text:
        "Ashton finds a strange website that promises to make all of his dreams come true. He enters his information but instead of finding his his dream woman he is transformed into her. He transforms into Crystal, a mega busty blonde bimbo slut who can't get enough cock. She tries to manage her new life and hold onto her old persona but soon finds that it's so much easier to let go and just get fucked over and over again like a good bimbo should.",
      title_text: "Crystal Dreams",
      isnsfw_boolean: true,
      username_text: "rodimus",
      _id: "1714807251675x827239883009687600",
      _version: 423939825,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1714807251675x827239883009687600",
  },
  {
    _version: 422511930,
    found: true,
    _source: {
      "Modified Date": 1727888206939,
      "Created Date": 1710617573610,
      "Created By":
        "1348695171700984260__LOOKUP__1709972580897x465789807082480640",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1710613946811x874311230124195800",
      livestatus_text: "Active",
      score_number: 31,
      selectedscenenumberforlive_number: 3,
      lastupdatedtime_date: 1711690725312,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 2876975,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/88f1fca2-e82d-46a7-020c-c86c84bccb00/cover",
      description_text:
        "A couple in their early 30's. Just about ready to start a family, the wife has a deep need to experience her teen years for a short period before doing so. So finds a spell on the dark web and asks her husband if he would revert to a teen for a week.",
      title_text: "Rekindling Teen Desires",
      isnsfw_boolean: true,
      username_text: "sharkey",
      _id: "1710617568227x660081794698707000",
      _version: 422511930,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1710617568227x660081794698707000",
  },
  {
    _version: 421830568,
    found: true,
    _source: {
      "Modified Date": 1727821290885,
      "Created Date": 1717241331320,
      "Created By":
        "1348695171700984260__LOOKUP__1717240403707x425763552530411970",
      islive_boolean: true,
      linkedstory_custom_prostory1:
        "1348695171700984260__LOOKUP__1717241217532x822549200671342600",
      livestatus_text: "Active",
      score_number: 31,
      selectedscenenumberforlive_number: 1,
      lastupdatedtime_date: 1717241493665,
      potentially_high_risk_boolean: false,
      numberofcharacters_number: 4509,
      selectedcoverart_text:
        "https://imagedelivery.net/WGbHefNPMclSFpQdIsO0IA/f46dff26-7950-48e1-771b-1c03499f3000/public",
      description_text:
        "A story of police woman who is doing with gangster and police woman should have complete police dress at the start of the story ",
      title_text: "Undercover Seduction: Uniforms & Gangsters",
      isnsfw_boolean: true,
      username_text: "venkata",
      _id: "1717241314222x924695043825729500",
      _version: 421830568,
      _type: "custom.prostorylivestatus",
    },
    _type: "custom.prostorylivestatus",
    _id: "1717241314222x924695043825729500",
  },
];
