/* eslint-disable jsx-a11y/no-redundant-roles */
import { CheckIcon } from "@heroicons/react/20/solid";
import {
  LuClipboardX,
  LuClock3,
  LuDatabase,
  LuDownload,
  LuGift,
  LuHeart,
  LuImage,
  LuSparkles,
} from "react-icons/lu";
import { Button } from "../../../Components/button";
import React from "react";

const freePlan = {
  name: "FREE PLAN",
  description: "Start for free",
  id: "tier-hobby",
  href: "#",
  priceMonthly: "$29",
  features: [
    {
      title: "24hr wait between free token collection",
      icon: LuClock3,
    },
    {
      title: "Up to 8 per collection (48 per day).",
      icon: LuDatabase,
    },
    {
      title: "5 token bonus on sign up",
      icon: LuGift,
    },
  ],
};
const unlimitedPlan = {
  name: "Team",
  id: "tier-team",
  href: "#",
  priceMonthly: "$99",
  description:
    "Explicabo quo fugit vel facere ullam corrupti non dolores. Expedita eius sit sequi.",
  features: [
    {
      title: "Unlimited text generation",
      icon: LuSparkles,
    },
    {
      title: "2000 images per month",
      icon: LuImage,
    },
    {
      title: "Export and own your stories",
      icon: LuDownload,
    },
    {
      title: "Easily cancel anytime",
      icon: LuClipboardX,
    },
  ],
};

export default function Pricing() {
  const [activeTab, setActiveTab] = React.useState(1);
  return (
    <div className="isolate overflow-hidden ">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-16 text-center lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-3xl mt-6 font-bold tracking-tight text-foreground md:text-6xl">
            UNLEASH YOUR CREATIVITY WITH UNLIMITED TOKENS
          </h2>
        </div>
        <div className="relative mt-4">
          <p className="mx-auto max-w-md text-sm  text-foreground">
            Unlimited personalised stories, Cheaper than a paperback. Cancel
            anytime.
          </p>
        </div>
      </div>
      <div className="flow-root  pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-xl grid-cols-1 gap-4 lg:max-w-5xl lg:grid-cols-2">
              <div className="flex flex-col justify-between gap-10 rounded-3xl bg-gradient-to-tr from-secondary to-background p-8 shadow-xl border sm:p-10">
                <div>
                  <h3 className="text-4xl md:text-7xl tracking-tight font-bold ">
                    FREE <br className="hidden md:block" />
                    PLAN
                  </h3>
                  <div className="mt-4 flex items-baseline gap-x-2">
                    <span className="text-lg">{freePlan.description}</span>
                  </div>
                  <ul
                    role="list"
                    className="mt-10 space-y-5 text-lg leading-6 "
                  >
                    {freePlan.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <feature.icon size={24} />
                        {feature.title}
                      </li>
                    ))}
                  </ul>
                </div>
                <Button
                  size="xxl"
                  variant="outline"
                  className="border-primary rounded-xl"
                  href={freePlan.href}
                  aria-describedby={freePlan.id}
                >
                  Create my first story free
                </Button>
              </div>

              <div className="relative overflow-hidden rounded-3xl">
                <div className="absolute top-0 -z-10 w-full h-full">
                  <img
                    src="./assets/tpl01/pricing-bg.png"
                    alt="rating-bg-image"
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <div className="flex flex-col justify-between gap-10  p-8 shadow-xl  sm:p-10 relative">
                  <div>
                    <h3 className="text-4xl md:text-7xl tracking-tight font-bold ">
                      UNLIMITED <br className="hidden md:block" />
                      PLAN
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <div className="rounded-full bg-background flex">
                        <Button size="lg" className="rounded-full px-4">
                          Monthly
                        </Button>
                        <Button
                          variant="transparent_ghost"
                          className="rounded-full gap-4 pe-2 px-4"
                          size="lg"
                        >
                          Yearly{" "}
                          <span className="bg-primary/20 p-1 border border-primary/50 rounded-full px-2">
                            -30% off
                          </span>
                        </Button>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="mt-10 space-y-5 text-lg leading-6 "
                    >
                      {unlimitedPlan.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <feature.icon size={24} />
                          {feature.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Button
                    size="xxl"
                    className="btn"
                    href={freePlan.href}
                    aria-describedby={freePlan.id}
                  >
                    Get Unlimited stories
                  </Button>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
