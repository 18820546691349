export const exploreAiGIrlfriends = [
  // {
  //   name: "Akane Yamamoto",
  //   image:
  //     "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c3f89e46-e827-418d-8309-dd2331b01b9b/width=450/00048-3432400032(20230607031905%20CheckpointYesmix_v15%20768x1024).jpeg",
  //   description:
  //     "Akane likes to wear short clothes in public and flash them...",
  //   age: 22,
  // },
  // {
  //   name: "Yumi Nakamura",
  //   image:
  //     "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2bea74b8-bb8c-4d8e-b4e0-d74770f65a93/width=450/202309238.jpeg",
  //   description:
  //     "Blonde hair, gray eyes, and a Huge set of boobs make yumi special...",
  //   age: 22,
  // },
  // {
  //   name: "Hikari Tanaka",
  //   image:
  //     "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/01a00ee3-e330-436b-9d8e-31af71a89430/width=450/yariri-11.jpeg",
  //   description: "Hikari likes to tease her looks and she is a student at ...",
  //   age: 22,
  // },
  // {
  //   name: "Ayumi Watanabe",
  //   image:
  //     "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c7604243-0aa1-4914-df96-c10ad116a300/width=450/208345.jpeg",
  //   description: "Professional model with a great dressing sense but always...",
  //   age: 22,
  // },
  // {
  //   name: "Sakura Saito",
  //   image:
  //     "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0a657af7-0303-4e11-38ef-2a5683dc3f00/width=450/214895.jpeg",
  //   description:
  //     "18 year old girl likes to go to beach and wear bikini which barely covers her...",
  //   age: 22,
  // },
  {
    name: "Emiko Takahashi",
    image:
      "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c44108d7-03ec-4c09-2ba7-f8904fb0aa00/width=450/00114-3859511306-((masterpiece)),%20(best%20quality),%20(detailed),%20(1%20girl),%20%20,%20negligee,dress,%20frills,underwear,see-through,navel.jpeg",
    description:
      "Emiko is always shy and likes to flaunt her looks in biinni when noone's around...",
    age: 22,
  },
  {
    name: "Natsuki Ito",
    image:
      "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/57523515-c89b-4b83-e4a5-b768e7127700/width=450/00007-3102986754.jpeg",
    description: "A witch in bikini who likes to play with her broomstick...",
    age: 22,
  },
  {
    name: "Mayumi Sasaki",
    image:
      "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2d632b12-d0d8-493d-9258-2b1a1a7d37f0/width=450/02024.jpeg",
    description:
      "A calm and composed Mayumi is what everyone needs in their life...",
    age: 22,
  },
  {
    name: "Aiko Fujimoto",
    image:
      "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/db2fbbf7-4212-4cfd-a852-e761943810d0/width=450/00037-cefeddcec1-2326053900-DPM++%202M%20Karras%20v2-1152x1536-9546a7e4.jpeg",
    description:
      "She acquired rabbit traits when she was little and now she is a bunny girl...",
    age: 22,
  },
  {
    name: "Yurika Mori",
    image:
      "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/532409b1-a20c-4200-ad7d-43084bd2abd2/width=450/00020-anyhentai_20_3159254966.jpeg",
    description: "Fitness coach Yurika has the best body in the town...",
    age: 22,
  },
];
