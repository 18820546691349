import React from "react";
import StoryCard from "./story-card";
import { storiesData } from "./data";
import { Button } from "../../../Components/button";
import { LuChevronDown } from "react-icons/lu";
import { LucideTelescope } from "lucide-react";

const Stories = () => {
  return (
    <div className="max-w-7xl p-4 my-10 mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {storiesData.map((story) => (
          <StoryCard key={story.id} story={story} />
        ))}
      </div>
      <div className=" pt-10 space-y-20">
        <Button
          variant="outline"
          className="mt-4 border-white/20 rounded-xl hover:bg-background/20 mx-auto"
        >
          <LuChevronDown /> Show More
        </Button>
        <Button
          variant="default"
          size="xxl"
          className="mt-4 rounded-xl mx-auto px-16 text-base btn"
        >
          <LucideTelescope /> Explore All Stories
        </Button>
      </div>
    </div>
  );
};

export default Stories;
