import React from "react";

const Rating = () => {
  return (
    <div className="relative py-20 sm:py-40">
      <div className="absolute top-0 -z-10 w-full h-full">
        <img
          src="./assets/tpl01/rating-bg.png"
          alt="rating-bg-image"
          className="w-full h-full object-cover object-top"
        />
      </div>
      <div className=" text-center -space-y-2 md:-space-y-5">
        <p className="text-4xl sm:text-5xl md:text-6xl font-extrabold">
          LOVED BY OVER
        </p>
        <p className="text-5xl md:text-[102px] font-sans font-bold double-outline-rating-text text-primary">
          400000
        </p>
        <p className="text-4xl sm:text-5xl md:text-6xl font-extrabold">
          AUTHORS
        </p>
      </div>
      <div className="space-y-1.5 mt-4 text-center">
        <p>Rated over 4.5 stars on Trustpilot</p>
        <p>Check out some of their writing below</p>
      </div>
    </div>
  );
};

export default Rating;
