import React from "react";
import { exploreAiGIrlfriends } from "../../constants/girlfriends";
import GirlfirendCard from "../ai-girlfriend-card";

export default function ExploreAiGIrlfriend() {
  return (
    <section className="">
      <p className="text-center text-2xl font-bold text-foreground/90 ">
        Explore our best AI Hentai girls
      </p>
      <div className="mt-4 grid gap-2 md:gap-6 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {exploreAiGIrlfriends.map((girlfriend, index) => (
          <GirlfirendCard girlfriend={girlfriend} />
        ))}
      </div>
    </section>
  );
}
