"use client";

import { LuImage, LuMap, LuMenu, LuPen } from "react-icons/lu";
import { Button } from "../../../Components/button";
import { FaCircle } from "react-icons/fa";

const navigation = [
  { name: "Write", href: "#", icon: LuPen },
  { name: "Explore", href: "#", icon: LuMap },
  { name: "Images", href: "#", icon: LuImage },
];

export default function Navbar() {
  return (
    <header className="bg-transparent border-b lg:border-b-0">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-4 sm:p-6 lg:px-8"
      >
        <div className="hidden lg:flex flex-1">
          <div className="hidden lg:flex lg:gap-x-4">
            {navigation.map((item) => (
              <Button
                key={item.name}
                href={item.href}
                variant="transparent_ghost"
                className="gap-2"
              >
                <item.icon size={20} className="text-foreground/50" />
                {item.name}
              </Button>
            ))}
          </div>
        </div>
        <a href="#" className="-m-1.5 p-1.5">
          <div className="flex justify-center items-center text-2xl font-black tracking-widest text-primary ">
            <FaCircle
              className="absolute -top-20 blur-3xl text-primary"
              size={100}
            />
            Havengen.ai
          </div>{" "}
        </a>
        <div className="flex flex-1 justify-end">
          <Button href="#" className="btn">
            Log in / Sign up
          </Button>
        </div>
      </nav>
      {/* <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-1">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
            <div className="flex flex-1 justify-end">
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="mt-6 space-y-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                {item.name}
              </a>
            ))}
          </div>
        </DialogPanel>
      </Dialog> */}
    </header>
  );
}
