export const settings = () => {
  return window.lander.settings;
};
export const getStyles = () => {
  return window.lander.settings.styles || [];
};
export const getEthnicities = (style) => {
  const styles = getStyles();
  let currentStyle = "";
  for (let s of styles) {
    if (style === s.name) {
      currentStyle = s;
      break;
    }
  }
  const ethnicities = window.lander.settings.ethnicities;
  const baseUrl = currentStyle.base.replace(/\/$/, "") + "/ethnicities/";
  return { baseUrl: baseUrl, ethnicities: ethnicities };
};
export const getCurrentStyleKey = (style) => {
  const styles = getStyles();
  let currentStyle = {};
  for (let s of styles) {
    if (style === s.name) {
      currentStyle = s;
      break;
    }
  }
  return currentStyle.key;
};
export const getCurrentStyleIndex = (style) => {
  const styles = getStyles();
  let idx = 0;
  for (let s of styles) {
    if (style === s.name) {
      break;
    }
    idx++;
  }
  return idx;
};
export const getHairOptions = () => {
  return window.lander.settings.hair.options;
};
export const getHairTypes = (style) => {
  const styles = getStyles();
  let currentStyle = "";
  for (let s of styles) {
    if (style === s.name) {
      currentStyle = s;
      break;
    }
  }
  const types = window.lander.settings.hair.types;
  const baseUrl = currentStyle.base.replace(/\/$/, "") + "/hair/";
  return { baseUrl: baseUrl, types: types };
};
export const getBodyOptions = () => {
  return window.lander.settings.body.options;
};
export const getBodyTypes = (style) => {
  const styles = getStyles();
  let currentStyle = "";
  for (let s of styles) {
    if (style === s.name) {
      currentStyle = s;
      break;
    }
  }
  const types = window.lander.settings.body.types;
  const baseUrl = currentStyle.base.replace(/\/$/, "") + "/body/";
  return { baseUrl: baseUrl, types: types };
};
export const assetUrl = (asset) => {
  return (
    window.lander.baseUrl.replace(/\/$/, "") + "/" + asset.replace(/^\//, "")
  );
};

export const strapiImage = (asset) => {
  const strapiUrl =
    process.env.REACT_APP_STRAPI_URL || "http://195.16.73.90:1337";
  return strapiUrl + asset;
};

export const getVoices = () => {
  return window.lander.settings.voices;
};
export const getRelationships = () => {
  return window.lander.settings.relationships;
};
export const getCurrentRelationship = (relationship) => {
  const rels = getRelationships();
  let el = {};
  for (let els of rels) {
    for (let e of els) {
      if (e.name === relationship) {
        el = e;
        break;
      }
    }
  }
  return el;
};
export const getCurrentEthnicity = (style, ethnicity) => {
  const ets = getEthnicities(style);
  let el = {};
  for (let e of ets.ethnicities) {
    if (e.name === ethnicity) {
      el = e;
      break;
    }
  }
  return { baseUrl: ets.baseUrl, ethnicity: el };
};
export const getCurrentVoice = (voice) => {
  return window.lander.settings.voices.indexOf(voice) + 1;
};
export const getCurrentBodyType = (style, type, value) => {
  const { baseUrl } = getBodyTypes(style);
  const types = getBodyOptions(style)[type];
  let el = {};
  for (let e of types) {
    if (e.name === value) {
      el = e;
      break;
    }
  }
  return { baseUrl: baseUrl + type + "/", bodyType: el };
};
export const getCurrentHairStyle = (style, type, value) => {
  const { baseUrl } = getHairTypes(style);
  const types = getHairOptions(style)[type];
  let el = {};
  for (let e of types) {
    if (e.name === value) {
      el = e;
      break;
    }
  }
  return { baseUrl: baseUrl + type + "/", style: el };
};
