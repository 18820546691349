import React from "react";
import Banner from "./components/banner";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import Rating from "./components/rating";
import SearchFilter from "./components/search-filter";
import Stories from "./components/stories";
import CTA from "./components/CTA";
import Pricing from "./components/pricing";
import Footer from "./components/footer";
import BottomNavbar from "./components/bottom-navbar";

export default function TPL02() {
  return (
    <main className="relative z-10">
      <div className="absolute top-0 -z-10 w-full h-full">
        <img
          src="./assets/tpl01/background.png"
          alt="rating-bg-image"
          className="w-full h-full object-cover opacity-75 object-top"
        />
      </div>

      <Banner />
      <BottomNavbar />
      <Navbar />
      <Hero />
      <Rating />
      <SearchFilter />
      <Stories />
      <CTA />
      <div className="relative">
        <div className="absolute top-0 -z-10 w-full h-full">
          <img
            src="./assets/tpl01/bottom-gradient.svg"
            alt="rating-bg-image"
            className="w-full h-full object-cover object-center"
          />
        </div>
        <Pricing />
        <Footer />
      </div>
    </main>
  );
}
